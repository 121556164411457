import * as auth0 from 'auth0-js';

const APP_PREFIX = 'refunds';

export const AUTH_ID_TOKEN_KEY = APP_PREFIX+'-idToken';
export const AUTH_ID_KEY = APP_PREFIX+'-authId';
export const AUTH_ACCESS_TOKEN_KEY = APP_PREFIX+'-accessToken';
export const AUTH_NAME = APP_PREFIX+'-name';
export const AUTH_EMAIL = APP_PREFIX+'-email';
export const AUTH_CLIENT_ID = 'tyvkofkNbhnxjhcowAsD2FRxAd2QO1z7';

const webAuth = new auth0.WebAuth({
    domain: 'dxo.eu.auth0.com',
    clientID: AUTH_CLIENT_ID,
    responseType: 'token id_token',
    redirectUri: window.location.origin + '/callback',
    audience: 'https://app.dx.no/',
});

export function login () {
    webAuth.authorize();
}

export function logout() {
    console.log('logout');
    webAuth.logout({
        clientID: AUTH_CLIENT_ID,
        returnTo: window.location.origin,
    });
    localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
    localStorage.removeItem(AUTH_ID_TOKEN_KEY);
    localStorage.removeItem(AUTH_ID_KEY);
}

export function loginCallback (port) {
    webAuth.parseHash({ hash: window.location.hash }, function(err, result) {
        if (err) {
            return console.error(err);
        }

        if (result) {
            console.log(result);
            const idToken = result.idToken;
            const authId = result.idTokenPayload.sub;
            const accessToken = result.accessToken;
            const name = result.idTokenPayload.given_name + ' ' + result.idTokenPayload.family_name;
            const email = result.idTokenPayload.email;
            localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, accessToken);
            localStorage.setItem(AUTH_ID_TOKEN_KEY, idToken);
            localStorage.setItem(AUTH_ID_KEY, authId);
            localStorage.setItem(AUTH_NAME, name);
            localStorage.setItem(AUTH_EMAIL, email);
            port.send({ accessToken, name, email });
            window.location.hash = '';
        }
    });
}

