(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === elm$core$Basics$EQ ? 0 : ord === elm$core$Basics$LT ? -1 : 1;
	}));
});



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = elm$core$Set$toList(x);
		y = elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? elm$core$Basics$LT : n ? elm$core$Basics$GT : elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.aR.U === region.a5.U)
	{
		return 'on line ' + region.aR.U;
	}
	return 'on lines ' + region.aR.U + ' through ' + region.a5.U;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? elm$core$Maybe$Nothing
		: elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? elm$core$Maybe$Just(n) : elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




/**_UNUSED/
function _Json_errorToString(error)
{
	return elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? elm$core$Result$Ok(value)
		: (value instanceof String)
			? elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!elm$core$Result$isOk(result))
					{
						return elm$core$Result$Err(A2(elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return elm$core$Result$Ok(elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if (elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return elm$core$Result$Err(elm$json$Json$Decode$OneOf(elm$core$List$reverse(errors)));

		case 1:
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!elm$core$Result$isOk(result))
		{
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2(elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cC,
		impl.db,
		impl.c4,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.cr.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done(elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done(elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.cr.b, xhr)); });
		elm$core$Maybe$isJust(request.b4) && _Http_track(router, xhr, request.b4.a);

		try {
			xhr.open(request.bn, request.b6, true);
		} catch (e) {
			return done(elm$http$Http$BadUrl_(request.b6));
		}

		_Http_configureRequest(xhr, request);

		request.cg.a && xhr.setRequestHeader('Content-Type', request.cg.a);
		xhr.send(request.cg.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bb; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.b3.a || 0;
	xhr.responseType = request.cr.d;
	xhr.withCredentials = request.G;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? elm$http$Http$GoodStatus_ : elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		b6: xhr.responseURL,
		bZ: xhr.status,
		c1: xhr.statusText,
		bb: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return elm$core$Dict$empty;
	}

	var headers = elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3(elm$core$Dict$update, key, function(oldValue) {
				return elm$core$Maybe$Just(elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2(elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, elm$http$Http$Sending({
			c_: event.loaded,
			aK: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2(elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, elm$http$Http$Receiving({
			cU: event.loaded,
			aK: event.lengthComputable ? elm$core$Maybe$Just(event.total) : elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return elm$core$Maybe$Nothing;
	}
}



// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2(elm$json$Json$Decode$map, func, handler.a)
				:
			A3(elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bm: func(record.bm),
		b_: record.b_,
		bG: record.bG
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bm;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.b_;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bG) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cC,
		impl.db,
		impl.c4,
		function(sendToApp, initialModel) {
			var view = impl.dc;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cC,
		impl.db,
		impl.c4,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.X && impl.X(sendToApp)
			var view = impl.dc;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.getElementById('elm-root');
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('div')(_List_Nil)(doc.cg);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.c5) && (_VirtualDom_doc.title = title = doc.c5);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.cM;
	var onUrlRequest = impl.cN;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		X: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bK === next.bK
							&& curr.bd === next.bd
							&& curr.bD.a === next.bD.a
						)
							? elm$browser$Browser$Internal(next)
							: elm$browser$Browser$External(href)
					));
				}
			});
		},
		cC: function(flags)
		{
			return A3(impl.cC, flags, _Browser_getUrl(), key);
		},
		dc: impl.dc,
		db: impl.db,
		c4: impl.c4
	});
}

function _Browser_getUrl()
{
	return elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return elm$core$Result$isOk(result) ? elm$core$Maybe$Just(result.a) : elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cz: 'hidden', ch: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cz: 'mozHidden', ch: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cz: 'msHidden', ch: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cz: 'webkitHidden', ch: 'webkitvisibilitychange' }
		: { cz: 'hidden', ch: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail(elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bT: _Browser_getScene(),
		b7: {
			aq: _Browser_window.pageXOffset,
			ar: _Browser_window.pageYOffset,
			P: _Browser_doc.documentElement.clientWidth,
			I: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		P: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		I: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bT: {
				P: node.scrollWidth,
				I: node.scrollHeight
			},
			b7: {
				aq: node.scrollLeft,
				ar: node.scrollTop,
				P: node.clientWidth,
				I: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bT: _Browser_getScene(),
			b7: {
				aq: x,
				ar: y,
				P: _Browser_doc.documentElement.clientWidth,
				I: _Browser_doc.documentElement.clientHeight
			},
			cn: {
				aq: x + rect.left,
				ar: y + rect.top,
				P: rect.width,
				I: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var author$project$Main$ChangedUrl = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$ClickedLink = function (a) {
	return {$: 2, a: a};
};
var author$project$Auth$Authorizing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var author$project$Auth$Guest = function (a) {
	return {$: 0, a: a};
};
var author$project$Auth$LoggedIn = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var author$project$Auth$authorize = F3(
	function (key, auth, perms) {
		if (auth.$ === 1) {
			return author$project$Auth$Guest(key);
		} else {
			var a = auth.a;
			if (perms.$ === 1) {
				return A2(author$project$Auth$Authorizing, key, a);
			} else {
				var p = perms.a;
				return A3(author$project$Auth$LoggedIn, key, a, p);
			}
		}
	});
var elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var elm$core$Array$branchFactor = 32;
var elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var elm$core$Basics$EQ = 1;
var elm$core$Basics$GT = 2;
var elm$core$Basics$LT = 0;
var elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var elm$core$List$cons = _List_cons;
var elm$core$Dict$toList = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var elm$core$Dict$keys = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2(elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var elm$core$Set$toList = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$keys(dict);
};
var elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var elm$core$Array$foldr = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldr,
			helper,
			A3(elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var elm$core$Array$toList = function (array) {
	return A3(elm$core$Array$foldr, elm$core$List$cons, _List_Nil, array);
};
var elm$core$Basics$ceiling = _Basics_ceiling;
var elm$core$Basics$fdiv = _Basics_fdiv;
var elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var elm$core$Basics$toFloat = _Basics_toFloat;
var elm$core$Array$shiftStep = elm$core$Basics$ceiling(
	A2(elm$core$Basics$logBase, 2, elm$core$Array$branchFactor));
var elm$core$Elm$JsArray$empty = _JsArray_empty;
var elm$core$Array$empty = A4(elm$core$Array$Array_elm_builtin, 0, elm$core$Array$shiftStep, elm$core$Elm$JsArray$empty, elm$core$Elm$JsArray$empty);
var elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var elm$core$List$reverse = function (list) {
	return A3(elm$core$List$foldl, elm$core$List$cons, _List_Nil, list);
};
var elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodes);
			var node = _n0.a;
			var remainingNodes = _n0.b;
			var newAcc = A2(
				elm$core$List$cons,
				elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var elm$core$Basics$eq = _Utils_equal;
var elm$core$Tuple$first = function (_n0) {
	var x = _n0.a;
	return x;
};
var elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = elm$core$Basics$ceiling(nodeListSize / elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2(elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var elm$core$Basics$add = _Basics_add;
var elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var elm$core$Basics$floor = _Basics_floor;
var elm$core$Basics$gt = _Utils_gt;
var elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var elm$core$Basics$mul = _Basics_mul;
var elm$core$Basics$sub = _Basics_sub;
var elm$core$Elm$JsArray$length = _JsArray_length;
var elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.g),
				elm$core$Array$shiftStep,
				elm$core$Elm$JsArray$empty,
				builder.g);
		} else {
			var treeLen = builder.e * elm$core$Array$branchFactor;
			var depth = elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? elm$core$List$reverse(builder.h) : builder.h;
			var tree = A2(elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.g) + treeLen,
				A2(elm$core$Basics$max, 5, depth * elm$core$Array$shiftStep),
				tree,
				builder.g);
		}
	});
var elm$core$Basics$False = 1;
var elm$core$Basics$idiv = _Basics_idiv;
var elm$core$Basics$lt = _Utils_lt;
var elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					elm$core$Array$builderToArray,
					false,
					{h: nodeList, e: (len / elm$core$Array$branchFactor) | 0, g: tail});
			} else {
				var leaf = elm$core$Array$Leaf(
					A3(elm$core$Elm$JsArray$initialize, elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2(elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var elm$core$Basics$le = _Utils_le;
var elm$core$Basics$remainderBy = _Basics_remainderBy;
var elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return elm$core$Array$empty;
		} else {
			var tailLen = len % elm$core$Array$branchFactor;
			var tail = A3(elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - elm$core$Array$branchFactor;
			return A5(elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var elm$core$Maybe$Nothing = {$: 1};
var elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var elm$core$Basics$True = 0;
var elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var elm$core$Basics$and = _Basics_and;
var elm$core$Basics$append = _Utils_append;
var elm$core$Basics$or = _Basics_or;
var elm$core$Char$toCode = _Char_toCode;
var elm$core$Char$isLower = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var elm$core$Char$isUpper = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var elm$core$Char$isAlpha = function (_char) {
	return elm$core$Char$isLower(_char) || elm$core$Char$isUpper(_char);
};
var elm$core$Char$isDigit = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var elm$core$Char$isAlphaNum = function (_char) {
	return elm$core$Char$isLower(_char) || (elm$core$Char$isUpper(_char) || elm$core$Char$isDigit(_char));
};
var elm$core$List$length = function (xs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var elm$core$List$map2 = _List_map2;
var elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2(elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var elm$core$List$range = F2(
	function (lo, hi) {
		return A3(elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$map2,
			f,
			A2(
				elm$core$List$range,
				0,
				elm$core$List$length(xs) - 1),
			xs);
	});
var elm$core$String$all = _String_all;
var elm$core$String$fromInt = _String_fromNumber;
var elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var elm$core$String$uncons = _String_uncons;
var elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var elm$json$Json$Decode$indent = function (str) {
	return A2(
		elm$core$String$join,
		'\n    ',
		A2(elm$core$String$split, '\n', str));
};
var elm$json$Json$Encode$encode = _Json_encode;
var elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + (elm$core$String$fromInt(i + 1) + (') ' + elm$json$Json$Decode$indent(
			elm$json$Json$Decode$errorToString(error))));
	});
var elm$json$Json$Decode$errorToString = function (error) {
	return A2(elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _n1 = elm$core$String$uncons(f);
						if (_n1.$ === 1) {
							return false;
						} else {
							var _n2 = _n1.a;
							var _char = _n2.a;
							var rest = _n2.b;
							return elm$core$Char$isAlpha(_char) && A2(elm$core$String$all, elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + (elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									elm$core$String$join,
									'',
									elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										elm$core$String$join,
										'',
										elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + (elm$core$String$fromInt(
								elm$core$List$length(errors)) + ' ways:'));
							return A2(
								elm$core$String$join,
								'\n\n',
								A2(
									elm$core$List$cons,
									introduction,
									A2(elm$core$List$indexedMap, elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								elm$core$String$join,
								'',
								elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + (elm$json$Json$Decode$indent(
						A2(elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var elm$json$Json$Decode$map2 = _Json_map2;
var NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = elm$json$Json$Decode$map2(elm$core$Basics$apR);
var elm$json$Json$Decode$field = _Json_decodeField;
var NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2(elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var author$project$Auth$PermissionsResponse = function (roles) {
	return {aJ: roles};
};
var author$project$Auth$Role = F3(
	function (name, partner, renter) {
		return {cK: name, cP: partner, cX: renter};
	});
var elm$json$Json$Decode$int = _Json_decodeInt;
var elm$json$Json$Decode$string = _Json_decodeString;
var elm$json$Json$Decode$succeed = _Json_succeed;
var author$project$Auth$decodeRole = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'renterID',
	elm$json$Json$Decode$int,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'partnerID',
		elm$json$Json$Decode$int,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			elm$json$Json$Decode$string,
			elm$json$Json$Decode$succeed(author$project$Auth$Role))));
var elm$json$Json$Decode$list = _Json_decodeList;
var author$project$Auth$decodePermissionsResponse = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'roles',
	elm$json$Json$Decode$list(author$project$Auth$decodeRole),
	elm$json$Json$Decode$succeed(author$project$Auth$PermissionsResponse));
var author$project$Auth$toToken = function (auth) {
	return auth.F;
};
var elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var elm$core$Platform$Cmd$batch = _Platform_batch;
var elm$core$Platform$Cmd$none = elm$core$Platform$Cmd$batch(_List_Nil);
var elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var elm$core$Dict$empty = elm$core$Dict$RBEmpty_elm_builtin;
var elm$core$Basics$compare = _Utils_compare;
var elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _n1 = A2(elm$core$Basics$compare, targetKey, key);
				switch (_n1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var elm$core$Dict$Black = 1;
var elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var elm$core$Dict$Red = 0;
var elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _n1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _n3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _n5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _n6 = left.d;
				var _n7 = _n6.a;
				var llK = _n6.b;
				var llV = _n6.c;
				var llLeft = _n6.d;
				var llRight = _n6.e;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5(elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _n1 = A2(elm$core$Basics$compare, key, nKey);
			switch (_n1) {
				case 0:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3(elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5(elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3(elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _n0 = A3(elm$core$Dict$insertHelp, key, value, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var lLeft = _n1.d;
			var lRight = _n1.e;
			var _n2 = dict.e;
			var rClr = _n2.a;
			var rK = _n2.b;
			var rV = _n2.c;
			var rLeft = _n2.d;
			var _n3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _n2.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n4 = dict.d;
			var lClr = _n4.a;
			var lK = _n4.b;
			var lV = _n4.c;
			var lLeft = _n4.d;
			var lRight = _n4.e;
			var _n5 = dict.e;
			var rClr = _n5.a;
			var rK = _n5.b;
			var rV = _n5.c;
			var rLeft = _n5.d;
			var rRight = _n5.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var _n2 = _n1.d;
			var _n3 = _n2.a;
			var llK = _n2.b;
			var llV = _n2.c;
			var llLeft = _n2.d;
			var llRight = _n2.e;
			var lRight = _n1.e;
			var _n4 = dict.e;
			var rClr = _n4.a;
			var rK = _n4.b;
			var rV = _n4.c;
			var rLeft = _n4.d;
			var rRight = _n4.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n5 = dict.d;
			var lClr = _n5.a;
			var lK = _n5.b;
			var lV = _n5.c;
			var lLeft = _n5.d;
			var lRight = _n5.e;
			var _n6 = dict.e;
			var rClr = _n6.a;
			var rK = _n6.b;
			var rV = _n6.c;
			var rLeft = _n6.d;
			var rRight = _n6.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _n1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_n2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _n3 = right.a;
							var _n4 = right.d;
							var _n5 = _n4.a;
							return elm$core$Dict$moveRedRight(dict);
						} else {
							break _n2$2;
						}
					} else {
						var _n6 = right.a;
						var _n7 = right.d;
						return elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _n2$2;
				}
			}
			return dict;
		}
	});
var elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _n3 = lLeft.a;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					elm$core$Dict$removeMin(left),
					right);
			} else {
				var _n4 = elm$core$Dict$moveRedLeft(dict);
				if (_n4.$ === -1) {
					var nColor = _n4.a;
					var nKey = _n4.b;
					var nValue = _n4.c;
					var nLeft = _n4.d;
					var nRight = _n4.e;
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _n4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _n6 = lLeft.a;
						return A5(
							elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2(elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _n7 = elm$core$Dict$moveRedLeft(dict);
						if (_n7.$ === -1) {
							var nColor = _n7.a;
							var nKey = _n7.b;
							var nValue = _n7.c;
							var nLeft = _n7.d;
							var nRight = _n7.e;
							return A5(
								elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2(elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2(elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7(elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _n1 = elm$core$Dict$getMin(right);
				if (_n1.$ === -1) {
					var minKey = _n1.b;
					var minValue = _n1.c;
					return A5(
						elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						elm$core$Dict$removeMin(right));
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2(elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var elm$core$Dict$remove = F2(
	function (key, dict) {
		var _n0 = A2(elm$core$Dict$removeHelp, key, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _n0 = alter(
			A2(elm$core$Dict$get, targetKey, dictionary));
		if (!_n0.$) {
			var value = _n0.a;
			return A3(elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2(elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var elm$core$Platform$sendToApp = _Platform_sendToApp;
var elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return elm$core$Result$Err(e);
		}
	});
var elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var elm$http$Http$NetworkError_ = {$: 2};
var elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$Timeout_ = {$: 1};
var elm$http$Http$emptyBody = _Http_emptyBody;
var elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return elm$core$Result$Err(
				f(e));
		}
	});
var elm$core$Basics$identity = function (x) {
	return x;
};
var elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			elm$core$Basics$identity,
			A2(elm$core$Basics$composeR, toResult, toMsg));
	});
var elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$NetworkError = {$: 2};
var elm$http$Http$Timeout = {$: 1};
var elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return elm$core$Result$Err(
					elm$http$Http$BadUrl(url));
			case 1:
				return elm$core$Result$Err(elm$http$Http$Timeout);
			case 2:
				return elm$core$Result$Err(elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return elm$core$Result$Err(
					elm$http$Http$BadStatus(metadata.bZ));
			default:
				var body = response.b;
				return A2(
					elm$core$Result$mapError,
					elm$http$Http$BadBody,
					toResult(body));
		}
	});
var elm$json$Json$Decode$decodeString = _Json_runOnString;
var elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			elm$http$Http$expectStringResponse,
			toMsg,
			elm$http$Http$resolve(
				function (string) {
					return A2(
						elm$core$Result$mapError,
						elm$json$Json$Decode$errorToString,
						A2(elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$http$Http$header = elm$http$Http$Header;
var elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var elm$core$Task$succeed = _Scheduler_succeed;
var elm$http$Http$State = F2(
	function (reqs, subs) {
		return {bN: reqs, b$: subs};
	});
var elm$http$Http$init = elm$core$Task$succeed(
	A2(elm$http$Http$State, elm$core$Dict$empty, _List_Nil));
var elm$core$Task$andThen = _Scheduler_andThen;
var elm$core$Process$kill = _Scheduler_kill;
var elm$core$Process$spawn = _Scheduler_spawn;
var elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _n2 = A2(elm$core$Dict$get, tracker, reqs);
					if (_n2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _n2.a;
						return A2(
							elm$core$Task$andThen,
							function (_n3) {
								return A3(
									elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2(elm$core$Dict$remove, tracker, reqs));
							},
							elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						elm$core$Task$andThen,
						function (pid) {
							var _n4 = req.b4;
							if (_n4.$ === 1) {
								return A3(elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _n4.a;
								return A3(
									elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3(elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			elm$core$Task$andThen,
			function (reqs) {
				return elm$core$Task$succeed(
					A2(elm$http$Http$State, reqs, subs));
			},
			A3(elm$http$Http$updateReqs, router, cmds, state.bN));
	});
var elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							elm$core$List$foldl,
							fn,
							acc,
							elm$core$List$reverse(r4)) : A4(elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4(elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _n0 = f(mx);
		if (!_n0.$) {
			var x = _n0.a;
			return A2(elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return A2(
					elm$core$Task$andThen,
					function (b) {
						return elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var elm$core$Task$sequence = function (tasks) {
	return A3(
		elm$core$List$foldr,
		elm$core$Task$map2(elm$core$List$cons),
		elm$core$Task$succeed(_List_Nil),
		tasks);
};
var elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _n0) {
		var actualTracker = _n0.a;
		var toMsg = _n0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? elm$core$Maybe$Just(
			A2(
				elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : elm$core$Maybe$Nothing;
	});
var elm$http$Http$onSelfMsg = F3(
	function (router, _n0, state) {
		var tracker = _n0.a;
		var progress = _n0.b;
		return A2(
			elm$core$Task$andThen,
			function (_n1) {
				return elm$core$Task$succeed(state);
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$filterMap,
					A3(elm$http$Http$maybeSend, router, tracker, progress),
					state.b$)));
	});
var elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return elm$http$Http$Request(
				{
					G: r.G,
					cg: r.cg,
					cr: A2(_Http_mapExpect, func, r.cr),
					bb: r.bb,
					bn: r.bn,
					b3: r.b3,
					b4: r.b4,
					b6: r.b6
				});
		}
	});
var elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$http$Http$subMap = F2(
	function (func, _n0) {
		var tracker = _n0.a;
		var toMsg = _n0.b;
		return A2(
			elm$http$Http$MySub,
			tracker,
			A2(elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager(elm$http$Http$init, elm$http$Http$onEffects, elm$http$Http$onSelfMsg, elm$http$Http$cmdMap, elm$http$Http$subMap);
var elm$http$Http$command = _Platform_leaf('Http');
var elm$http$Http$subscription = _Platform_leaf('Http');
var elm$http$Http$request = function (r) {
	return elm$http$Http$command(
		elm$http$Http$Request(
			{G: false, cg: r.cg, cr: r.cr, bb: r.bb, bn: r.bn, b3: r.b3, b4: r.b4, b6: r.b6}));
};
var krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var author$project$Auth$fetchRoles = F2(
	function (user, toMsg) {
		if (user.$ === 1) {
			var k = user.a;
			var a = user.b;
			return elm$http$Http$request(
				{
					cg: elm$http$Http$emptyBody,
					cr: A2(
						elm$http$Http$expectJson,
						A2(elm$core$Basics$composeR, krisajenkins$remotedata$RemoteData$fromResult, toMsg),
						author$project$Auth$decodePermissionsResponse),
					bb: _List_fromArray(
						[
							A2(
							elm$http$Http$header,
							'Authorization',
							'Bearer ' + author$project$Auth$toToken(a))
						]),
					bn: 'GET',
					b3: elm$core$Maybe$Nothing,
					b4: elm$core$Maybe$Nothing,
					b6: 'https://app.dx.no/getrolesfromtoken'
				});
		} else {
			return elm$core$Platform$Cmd$none;
		}
	});
var elm$json$Json$Encode$null = _Json_encodeNull;
var author$project$Auth$login = _Platform_outgoingPort(
	'login',
	function ($) {
		return elm$json$Json$Encode$null;
	});
var author$project$Auth$toAuth = F3(
	function (token, name, email) {
		var _n0 = _Utils_Tuple3(token, name, email);
		if (((!_n0.a.$) && (!_n0.b.$)) && (!_n0.c.$)) {
			var t = _n0.a.a;
			var n = _n0.b.a;
			var e = _n0.c.a;
			return elm$core$Maybe$Just(
				{F: t, R: e, cK: n});
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var author$project$Auth$init = F4(
	function (key, creds, loginIfGuest, toMsg) {
		var auth = A3(author$project$Auth$toAuth, creds.F, creds.cK, creds.R);
		var user = A3(author$project$Auth$authorize, key, auth, elm$core$Maybe$Nothing);
		switch (user.$) {
			case 0:
				if (!loginIfGuest) {
					return _Utils_Tuple2(user, elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						user,
						author$project$Auth$login(0));
				}
			case 1:
				var k = user.a;
				var a = user.b;
				return _Utils_Tuple2(
					user,
					A2(author$project$Auth$fetchRoles, user, toMsg));
			default:
				return _Utils_Tuple2(user, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Main$ReceivedPermissions = function (a) {
	return {$: 6, a: a};
};
var author$project$Main$GotNoTokenMsg = function (a) {
	return {$: 4, a: a};
};
var author$project$Main$GotRefundMsg = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$GotRemittanceMsg = function (a) {
	return {$: 5, a: a};
};
var author$project$Main$NoTokenModel = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$RefundModel = function (a) {
	return {$: 0, a: a};
};
var author$project$Main$RemittanceModel = function (a) {
	return {$: 2, a: a};
};
var elm$core$Platform$Cmd$map = _Platform_map;
var author$project$Main$updateWith = F3(
	function (toModel, toMsg, _n0) {
		var subModel = _n0.a;
		var subCmd = _n0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2(elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var author$project$Page$NoToken$NoToken = 0;
var author$project$Page$NoToken$init = _Utils_Tuple2(0, elm$core$Platform$Cmd$none);
var elm$json$Json$Decode$andThen = _Json_andThen;
var elm$json$Json$Decode$decodeValue = _Json_run;
var elm$json$Json$Decode$fail = _Json_fail;
var elm$json$Json$Decode$null = _Json_decodeNull;
var elm$json$Json$Decode$oneOf = _Json_oneOf;
var elm$json$Json$Decode$value = _Json_decodeValue;
var NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _n0 = A2(elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_n0.$) {
				var rawValue = _n0.a;
				var _n1 = A2(
					elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_n1.$) {
					var finalResult = _n1.a;
					return elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _n1.a;
					return elm$json$Json$Decode$fail(
						elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2(elm$json$Json$Decode$andThen, handleResult, elm$json$Json$Decode$value);
	});
var NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2(elm$json$Json$Decode$field, key, elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var author$project$Api$RefundCheck$RefundCheckResponse = F6(
	function (availableActions, event, partner, performed_action, expires, eventStatus) {
		return {cf: availableActions, cp: event, cq: eventStatus, ct: expires, cP: partner, cS: performed_action};
	});
var author$project$Api$RefundCheck$Event = F3(
	function (id, title, date) {
		return {ck: date, bf: id, c5: title};
	});
var author$project$Api$RefundCheck$decodeEvent = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'date',
	elm$json$Json$Decode$string,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		elm$json$Json$Decode$string,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			elm$json$Json$Decode$int,
			elm$json$Json$Decode$succeed(author$project$Api$RefundCheck$Event))));
var author$project$Api$RefundCheck$Partner = F2(
	function (id, name) {
		return {bf: id, cK: name};
	});
var author$project$Api$RefundCheck$decodePartner = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	elm$json$Json$Decode$string,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		elm$json$Json$Decode$int,
		elm$json$Json$Decode$succeed(author$project$Api$RefundCheck$Partner)));
var elm$json$Json$Decode$map = _Json_map1;
var elm$json$Json$Decode$nullable = function (decoder) {
	return elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				elm$json$Json$Decode$null(elm$core$Maybe$Nothing),
				A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, decoder)
			]));
};
var author$project$Api$RefundCheck$decodeResponse = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'refundType',
	elm$json$Json$Decode$string,
	A4(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'expires',
		elm$json$Json$Decode$nullable(elm$json$Json$Decode$int),
		elm$core$Maybe$Nothing,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'performed_action',
			elm$json$Json$Decode$string,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'partner',
				author$project$Api$RefundCheck$decodePartner,
				A3(
					NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'event',
					author$project$Api$RefundCheck$decodeEvent,
					A3(
						NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'availableActions',
						elm$json$Json$Decode$list(elm$json$Json$Decode$string),
						elm$json$Json$Decode$succeed(author$project$Api$RefundCheck$RefundCheckResponse)))))));
var author$project$Api$RefundCheck$mockCheckJson = '\n{"availableActions":["refund","giftcard"],"refundType":"cancelled","event":{"id":633,"title":"Test refund 2","date":"2020-05-05T19:00:00+02:00"},"partner":{"id":70,"name":"Kinokulturhuset DX"},"performed_action":"none","expires":1587890883947}\n';
var elm$http$Http$get = function (r) {
	return elm$http$Http$request(
		{cg: elm$http$Http$emptyBody, cr: r.cr, bb: _List_Nil, bn: 'GET', b3: elm$core$Maybe$Nothing, b4: elm$core$Maybe$Nothing, b6: r.b6});
};
var author$project$Api$RefundCheck$checkToken = F3(
	function (apiBaseUrl, _n0, toMsg) {
		var t = _n0;
		var mockJson = author$project$Api$RefundCheck$mockCheckJson;
		return elm$http$Http$get(
			{
				cr: A2(
					elm$http$Http$expectJson,
					A2(elm$core$Basics$composeR, krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					author$project$Api$RefundCheck$decodeResponse),
				b6: apiBaseUrl + ('/refund-check/' + t)
			});
	});
var author$project$Api$RefundCheck$Token = elm$core$Basics$identity;
var author$project$Api$RefundCheck$toToken = function (token) {
	return token;
};
var author$project$Page$Refund$CheckingStatus = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Refund$ReceivedTokenResponse = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var author$project$Page$Refund$init = F3(
	function (tokenString, baseUrl, currentTime) {
		var token = author$project$Api$RefundCheck$toToken(tokenString);
		return _Utils_Tuple2(
			{
				at: baseUrl,
				a1: currentTime,
				c0: author$project$Page$Refund$CheckingStatus(token)
			},
			A3(
				author$project$Api$RefundCheck$checkToken,
				baseUrl,
				token,
				author$project$Page$Refund$ReceivedTokenResponse(token)));
	});
var abadi199$elm_input_extra$MaskedInput$Text$State = elm$core$Basics$identity;
var abadi199$elm_input_extra$MaskedInput$Text$initialState = elm$core$Maybe$Nothing;
var author$project$Page$Remittance$Account = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var author$project$Page$Remittance$Clean = 0;
var author$project$Page$Remittance$Name = elm$core$Basics$identity;
var author$project$Page$Remittance$Pending = {$: 0};
var author$project$Page$Remittance$ReceivedTokenResponse = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Remittance$baseUrl = 'https://ebillett.no';
var author$project$Page$Remittance$EventResponse = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Remittance$SubscriptionResponse = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Remittance$TokenResponseEvent = F7(
	function (email, event, partner, ticketBuyer, purchase, refund, status) {
		return {R: email, cp: event, cP: partner, ak: purchase, al: refund, c0: status, ao: ticketBuyer};
	});
var author$project$Page$Remittance$TokenResponseSubscription = F7(
	function (email, subscriptionSeason, partner, ticketBuyer, purchase, refund, status) {
		return {R: email, cP: partner, ak: purchase, al: refund, c0: status, b0: subscriptionSeason, ao: ticketBuyer};
	});
var author$project$Page$Remittance$Event = F4(
	function (id, title, location, begin) {
		return {aX: begin, bf: id, cG: location, c5: title};
	});
var elm$time$Time$Posix = elm$core$Basics$identity;
var elm$time$Time$millisToPosix = elm$core$Basics$identity;
var author$project$Page$Remittance$decodeMillisToPosix = A2(
	elm$json$Json$Decode$map,
	A2(
		elm$core$Basics$composeR,
		elm$core$Basics$mul(1000),
		elm$time$Time$millisToPosix),
	elm$json$Json$Decode$int);
var author$project$Page$Remittance$decodeEvent = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'begin',
	author$project$Page$Remittance$decodeMillisToPosix,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'location',
		elm$json$Json$Decode$string,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'title',
			elm$json$Json$Decode$string,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				elm$json$Json$Decode$int,
				elm$json$Json$Decode$succeed(author$project$Page$Remittance$Event)))));
var author$project$Page$Remittance$Partner = F2(
	function (id, name) {
		return {bf: id, cK: name};
	});
var author$project$Page$Remittance$decodePartner = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	elm$json$Json$Decode$string,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		elm$json$Json$Decode$int,
		elm$json$Json$Decode$succeed(author$project$Page$Remittance$Partner)));
var author$project$Page$Remittance$Purchase = F4(
	function (id, count, amount, language) {
		return {aW: amount, au: count, bf: id, cE: language};
	});
var author$project$Page$Remittance$decodePurchase = A4(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'language',
	elm$json$Json$Decode$nullable(elm$json$Json$Decode$string),
	elm$core$Maybe$Nothing,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'amount',
		elm$json$Json$Decode$int,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'count',
			elm$json$Json$Decode$int,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				elm$json$Json$Decode$int,
				elm$json$Json$Decode$succeed(author$project$Page$Remittance$Purchase)))));
var author$project$Page$Remittance$Refund = F3(
	function (status, submittedAccountInfo, lastUpdated) {
		return {cF: lastUpdated, c0: status, c3: submittedAccountInfo};
	});
var author$project$Page$Remittance$Initialized = 0;
var author$project$Page$Remittance$PendingRefund = 2;
var author$project$Page$Remittance$ReceivedAccount = 1;
var author$project$Page$Remittance$Refunded = 3;
var author$project$Page$Remittance$UnknownStatus = 4;
var author$project$Page$Remittance$decodeRefundStatus = A2(
	elm$json$Json$Decode$andThen,
	function (status) {
		switch (status) {
			case 'initialized':
				return elm$json$Json$Decode$succeed(0);
			case 'recieved_account':
				return elm$json$Json$Decode$succeed(1);
			case 'pending':
				return elm$json$Json$Decode$succeed(2);
			case 'refunded':
				return elm$json$Json$Decode$succeed(3);
			default:
				return elm$json$Json$Decode$succeed(4);
		}
	},
	elm$json$Json$Decode$string);
var author$project$Page$Remittance$decodeRefund = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updated',
	A2(elm$json$Json$Decode$map, elm$time$Time$millisToPosix, elm$json$Json$Decode$int),
	A4(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'received_account',
		elm$json$Json$Decode$nullable(author$project$Page$Remittance$decodeMillisToPosix),
		elm$core$Maybe$Nothing,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'status',
			author$project$Page$Remittance$decodeRefundStatus,
			elm$json$Json$Decode$succeed(author$project$Page$Remittance$Refund))));
var author$project$Page$Remittance$SubscriptionSeason = F2(
	function (id, title) {
		return {bf: id, c5: title};
	});
var author$project$Page$Remittance$decodeSubscription = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	elm$json$Json$Decode$string,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		elm$json$Json$Decode$int,
		elm$json$Json$Decode$succeed(author$project$Page$Remittance$SubscriptionSeason)));
var author$project$Page$Remittance$TicketBuyer = function (name) {
	return {cK: name};
};
var author$project$Page$Remittance$decodeTicketBuyer = A2(
	elm$json$Json$Decode$andThen,
	function (str) {
		return elm$json$Json$Decode$succeed(
			author$project$Page$Remittance$TicketBuyer(str));
	},
	elm$json$Json$Decode$string);
var author$project$Page$Remittance$decodeTokenResponse = elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			elm$json$Json$Decode$map,
			author$project$Page$Remittance$EventResponse,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status',
				elm$json$Json$Decode$string,
				A3(
					NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'refund',
					author$project$Page$Remittance$decodeRefund,
					A3(
						NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'purchase',
						author$project$Page$Remittance$decodePurchase,
						A4(
							NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'profileName',
							elm$json$Json$Decode$nullable(author$project$Page$Remittance$decodeTicketBuyer),
							elm$core$Maybe$Nothing,
							A3(
								NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'partner',
								author$project$Page$Remittance$decodePartner,
								A3(
									NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'event',
									author$project$Page$Remittance$decodeEvent,
									A3(
										NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'email',
										elm$json$Json$Decode$string,
										elm$json$Json$Decode$succeed(author$project$Page$Remittance$TokenResponseEvent))))))))),
			A2(
			elm$json$Json$Decode$map,
			author$project$Page$Remittance$SubscriptionResponse,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status',
				elm$json$Json$Decode$string,
				A3(
					NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'refund',
					author$project$Page$Remittance$decodeRefund,
					A3(
						NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'purchase',
						author$project$Page$Remittance$decodePurchase,
						A4(
							NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'profileName',
							elm$json$Json$Decode$nullable(author$project$Page$Remittance$decodeTicketBuyer),
							elm$core$Maybe$Nothing,
							A3(
								NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'partner',
								author$project$Page$Remittance$decodePartner,
								A3(
									NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'subscriptionSeason',
									author$project$Page$Remittance$decodeSubscription,
									A3(
										NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'email',
										elm$json$Json$Decode$string,
										elm$json$Json$Decode$succeed(author$project$Page$Remittance$TokenResponseSubscription)))))))))
		]));
var author$project$Page$Remittance$fetchTokenInfo = function (token) {
	return elm$http$Http$get(
		{
			cr: A2(elm$http$Http$expectJson, author$project$Page$Remittance$ReceivedTokenResponse, author$project$Page$Remittance$decodeTokenResponse),
			b6: author$project$Page$Remittance$baseUrl + ('/refund/' + (token + '/details'))
		});
};
var author$project$Page$Remittance$init = F2(
	function (token, currentTime) {
		return _Utils_Tuple2(
			{
				v: A2(author$project$Page$Remittance$Account, abadi199$elm_input_extra$MaskedInput$Text$initialState, ''),
				r: 0,
				cK: '',
				aS: token,
				N: author$project$Page$Remittance$Pending
			},
			author$project$Page$Remittance$fetchTokenInfo(token));
	});
var author$project$Main$initPage = F3(
	function (page, apiBaseUrl, currentTime) {
		switch (page.$) {
			case 0:
				var token = page.a;
				return A3(
					author$project$Main$updateWith,
					author$project$Main$RefundModel,
					author$project$Main$GotRefundMsg,
					A3(author$project$Page$Refund$init, token, apiBaseUrl, currentTime));
			case 2:
				return A3(author$project$Main$updateWith, author$project$Main$NoTokenModel, author$project$Main$GotNoTokenMsg, author$project$Page$NoToken$init);
			default:
				var token = page.a;
				return A3(
					author$project$Main$updateWith,
					author$project$Main$RemittanceModel,
					author$project$Main$GotRemittanceMsg,
					A2(author$project$Page$Remittance$init, token, currentTime));
		}
	});
var author$project$Routes$NoToken = {$: 2};
var author$project$Routes$ChooseRefundOption = function (a) {
	return {$: 0, a: a};
};
var author$project$Routes$Remittance = function (a) {
	return {$: 1, a: a};
};
var elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var elm$url$Url$Parser$Parser = elm$core$Basics$identity;
var elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {z: frag, B: params, y: unvisited, D: value, E: visited};
	});
var elm$url$Url$Parser$mapState = F2(
	function (func, _n0) {
		var visited = _n0.E;
		var unvisited = _n0.y;
		var params = _n0.B;
		var frag = _n0.z;
		var value = _n0.D;
		return A5(
			elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var elm$url$Url$Parser$map = F2(
	function (subValue, _n0) {
		var parseArg = _n0;
		return function (_n1) {
			var visited = _n1.E;
			var unvisited = _n1.y;
			var params = _n1.B;
			var frag = _n1.z;
			var value = _n1.D;
			return A2(
				elm$core$List$map,
				elm$url$Url$Parser$mapState(value),
				parseArg(
					A5(elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3(elm$core$List$foldr, elm$core$List$cons, ys, xs);
		}
	});
var elm$core$List$concat = function (lists) {
	return A3(elm$core$List$foldr, elm$core$List$append, _List_Nil, lists);
};
var elm$core$List$concatMap = F2(
	function (f, list) {
		return elm$core$List$concat(
			A2(elm$core$List$map, f, list));
	});
var elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			elm$core$List$concatMap,
			function (_n0) {
				var parser = _n0;
				return parser(state);
			},
			parsers);
	};
};
var elm$url$Url$Parser$s = function (str) {
	return function (_n0) {
		var visited = _n0.E;
		var unvisited = _n0.y;
		var params = _n0.B;
		var frag = _n0.z;
		var value = _n0.D;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					elm$url$Url$Parser$State,
					A2(elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var elm$url$Url$Parser$slash = F2(
	function (_n0, _n1) {
		var parseBefore = _n0;
		var parseAfter = _n1;
		return function (state) {
			return A2(
				elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_n0) {
			var visited = _n0.E;
			var unvisited = _n0.y;
			var params = _n0.B;
			var frag = _n0.z;
			var value = _n0.D;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _n2 = stringToSomething(next);
				if (!_n2.$) {
					var nextValue = _n2.a;
					return _List_fromArray(
						[
							A5(
							elm$url$Url$Parser$State,
							A2(elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var elm$url$Url$Parser$string = A2(elm$url$Url$Parser$custom, 'STRING', elm$core$Maybe$Just);
var author$project$Routes$parser = elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			elm$url$Url$Parser$map,
			author$project$Routes$ChooseRefundOption,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('options'),
				elm$url$Url$Parser$string)),
			A2(
			elm$url$Url$Parser$map,
			author$project$Routes$Remittance,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('update-refund-information'),
				elm$url$Url$Parser$string))
		]));
var elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _n1 = state.y;
			if (!_n1.b) {
				return elm$core$Maybe$Just(state.D);
			} else {
				if ((_n1.a === '') && (!_n1.b.b)) {
					return elm$core$Maybe$Just(state.D);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				elm$core$List$cons,
				segment,
				elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var elm$url$Url$Parser$preparePath = function (path) {
	var _n0 = A2(elm$core$String$split, '/', path);
	if (_n0.b && (_n0.a === '')) {
		var segments = _n0.b;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _n0;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var elm$url$Url$percentDecode = _Url_percentDecode;
var elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return elm$core$Maybe$Just(
				A2(elm$core$List$cons, value, list));
		}
	});
var elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _n0 = A2(elm$core$String$split, '=', segment);
		if ((_n0.b && _n0.b.b) && (!_n0.b.b.b)) {
			var rawKey = _n0.a;
			var _n1 = _n0.b;
			var rawValue = _n1.a;
			var _n2 = elm$url$Url$percentDecode(rawKey);
			if (_n2.$ === 1) {
				return dict;
			} else {
				var key = _n2.a;
				var _n3 = elm$url$Url$percentDecode(rawValue);
				if (_n3.$ === 1) {
					return dict;
				} else {
					var value = _n3.a;
					return A3(
						elm$core$Dict$update,
						key,
						elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			elm$core$List$foldr,
			elm$url$Url$Parser$addParam,
			elm$core$Dict$empty,
			A2(elm$core$String$split, '&', qry));
	}
};
var elm$url$Url$Parser$parse = F2(
	function (_n0, url) {
		var parser = _n0;
		return elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					elm$url$Url$Parser$State,
					_List_Nil,
					elm$url$Url$Parser$preparePath(url.bz),
					elm$url$Url$Parser$prepareQuery(url.bL),
					url.a9,
					elm$core$Basics$identity)));
	});
var author$project$Routes$fromUrl = function (url) {
	var _n0 = A2(elm$url$Url$Parser$parse, author$project$Routes$parser, url);
	if (_n0.$ === 1) {
		return author$project$Routes$NoToken;
	} else {
		var p = _n0.a;
		return p;
	}
};
var author$project$Main$init = F3(
	function (flags, url, key) {
		var page = author$project$Routes$fromUrl(url);
		var currentTime = elm$time$Time$millisToPosix(flags.av);
		var creds = {F: elm$core$Maybe$Nothing, R: elm$core$Maybe$Nothing, cK: elm$core$Maybe$Nothing};
		var _n0 = A3(author$project$Main$initPage, page, flags.as, currentTime);
		var subModel = _n0.a;
		var subCmd = _n0.b;
		var _n1 = A4(author$project$Auth$init, key, creds, false, author$project$Main$ReceivedPermissions);
		var user = _n1.a;
		var initialCmd = _n1.b;
		return _Utils_Tuple2(
			{a1: currentTime, ai: page, C: subModel, aU: user},
			elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[initialCmd, subCmd])));
	});
var elm$core$Platform$Sub$batch = _Platform_batch;
var elm$core$Platform$Sub$none = elm$core$Platform$Sub$batch(_List_Nil);
var author$project$Page$Refund$subscriptions = function (model) {
	return elm$core$Platform$Sub$none;
};
var elm$core$Platform$Sub$map = _Platform_map;
var author$project$Main$subscriptions = function (model) {
	var _n0 = model.C;
	switch (_n0.$) {
		case 0:
			var refund = _n0.a;
			return A2(
				elm$core$Platform$Sub$map,
				author$project$Main$GotRefundMsg,
				author$project$Page$Refund$subscriptions(refund));
		case 1:
			var noToken = _n0.a;
			return elm$core$Platform$Sub$none;
		default:
			var remittance = _n0.a;
			return elm$core$Platform$Sub$none;
	}
};
var author$project$Auth$navKey = function (user) {
	switch (user.$) {
		case 0:
			var k = user.a;
			return k;
		case 1:
			var k = user.a;
			var a = user.b;
			return k;
		default:
			var k = user.a;
			var a = user.b;
			var i = user.c;
			return k;
	}
};
var author$project$Api$Refund$actionToString = function (action) {
	switch (action) {
		case 0:
			return 'no_action';
		case 1:
			return 'refund';
		default:
			return 'giftcard';
	}
};
var author$project$Api$Refund$RefundResponse = F4(
	function (status, possibleAction, refunds, giftcard) {
		return {cy: giftcard, cT: possibleAction, cW: refunds, c0: status};
	});
var author$project$Api$Refund$GiftcardInformation = F5(
	function (cardnumber, pin, expires, url, amount) {
		return {aW: amount, aZ: cardnumber, ct: expires, bB: pin, b6: url};
	});
var author$project$Api$Refund$decodeGiftcard = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'amount',
	elm$json$Json$Decode$int,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'url',
		elm$json$Json$Decode$string,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'expires',
			elm$json$Json$Decode$string,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'pin',
				elm$json$Json$Decode$string,
				A3(
					NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'cardNumber',
					elm$json$Json$Decode$string,
					elm$json$Json$Decode$succeed(author$project$Api$Refund$GiftcardInformation))))));
var author$project$Api$Refund$RefundInformation = F4(
	function (refundType, cardnumber, pin, amount) {
		return {aW: amount, aZ: cardnumber, bB: pin, cV: refundType};
	});
var author$project$Api$Refund$CreditcardType = 0;
var author$project$Api$Refund$GiftcardType = 1;
var author$project$Api$Refund$UnknownType = 2;
var author$project$Api$Refund$decodeRefundType = A2(
	elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'creditcard':
				return elm$json$Json$Decode$succeed(0);
			case 'giftcard':
				return elm$json$Json$Decode$succeed(1);
			default:
				return elm$json$Json$Decode$succeed(2);
		}
	},
	elm$json$Json$Decode$string);
var author$project$Api$Refund$decodeRefund = A3(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'amount',
	elm$json$Json$Decode$int,
	A4(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'pin',
		elm$json$Json$Decode$nullable(elm$json$Json$Decode$string),
		elm$core$Maybe$Nothing,
		A3(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'cardNumber',
			elm$json$Json$Decode$string,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'type',
				author$project$Api$Refund$decodeRefundType,
				elm$json$Json$Decode$succeed(author$project$Api$Refund$RefundInformation)))));
var author$project$Api$Refund$decodeRefundResponse = A4(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'giftcard',
	elm$json$Json$Decode$nullable(author$project$Api$Refund$decodeGiftcard),
	elm$core$Maybe$Nothing,
	A4(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'refunds',
		elm$json$Json$Decode$list(author$project$Api$Refund$decodeRefund),
		_List_Nil,
		A4(
			NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'possibleAction',
			elm$json$Json$Decode$nullable(elm$json$Json$Decode$string),
			elm$core$Maybe$Nothing,
			A3(
				NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status',
				elm$json$Json$Decode$string,
				elm$json$Json$Decode$succeed(author$project$Api$Refund$RefundResponse)))));
var author$project$Api$RefundCheck$toString = function (_n0) {
	var t = _n0;
	return t;
};
var elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			elm$core$List$foldl,
			F2(
				function (_n0, obj) {
					var k = _n0.a;
					var v = _n0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var elm$json$Json$Encode$string = _Json_wrap;
var author$project$Api$Refund$encodeRefundAction = F2(
	function (action, token) {
		return elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'action',
					elm$json$Json$Encode$string(action)),
					_Utils_Tuple2(
					'token',
					elm$json$Json$Encode$string(
						author$project$Api$RefundCheck$toString(token)))
				]));
	});
var author$project$Api$Refund$mockRefundResponseJson = '\n{"status":"OK","possibleAction":"NOTHING","refunds":[]}\n    ';
var elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2(elm$json$Json$Encode$encode, 0, value));
};
var elm$http$Http$post = function (r) {
	return elm$http$Http$request(
		{cg: r.cg, cr: r.cr, bb: _List_Nil, bn: 'POST', b3: elm$core$Maybe$Nothing, b4: elm$core$Maybe$Nothing, b6: r.b6});
};
var author$project$Api$Refund$doRefund = F4(
	function (baseUrl, action, token, toMsg) {
		var mockJson = author$project$Api$Refund$mockRefundResponseJson;
		var actionString = author$project$Api$Refund$actionToString(action);
		var body = elm$http$Http$jsonBody(
			A2(author$project$Api$Refund$encodeRefundAction, actionString, token));
		return elm$http$Http$post(
			{
				cg: body,
				cr: A2(
					elm$http$Http$expectJson,
					A2(elm$core$Basics$composeR, krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					author$project$Api$Refund$decodeRefundResponse),
				b6: baseUrl + '/refund'
			});
	});
var author$project$Page$Refund$ActionFailed = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Refund$ActionInProgress = {$: 0};
var author$project$Page$Refund$ActionPerformed = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Refund$ActionSelected = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Page$Refund$ReceiveRefundResponse = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var author$project$Page$Refund$RefundExpired = {$: 3};
var author$project$Page$Refund$StatusCheckFailed = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Refund$ValidToken = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var author$project$Page$Refund$Cancelled = 0;
var author$project$Page$Refund$Moved = 1;
var author$project$Page$Refund$eventFromResponse = function (response) {
	var status = function () {
		var _n0 = response.cq;
		if (_n0 === 'moved') {
			return 1;
		} else {
			return 0;
		}
	}();
	return {cl: response.cp.ck, a6: response.cp.bf, aw: response.cp.c5, by: response.cP.bf, cQ: response.cP.cK, c0: status};
};
var author$project$Page$Refund$sentry = _Platform_outgoingPort('sentry', elm$json$Json$Encode$string);
var author$project$Api$Refund$Giftcard = 2;
var author$project$Api$Refund$NoAction = 0;
var author$project$Api$Refund$Refund = 1;
var author$project$Api$Refund$stringToAction = function (action) {
	switch (action) {
		case 'no_action':
			return 0;
		case 'refund':
			return 1;
		case 'giftcard':
			return 2;
		default:
			return 0;
	}
};
var author$project$Page$Refund$RefundAvailable = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Refund$TokenUsed = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Refund$stateFromResponse = function (response) {
	var _n0 = elm$core$List$length(response.cf);
	if (!_n0) {
		return author$project$Page$Refund$TokenUsed(response.cS);
	} else {
		var availableOptions = A2(elm$core$List$map, author$project$Api$Refund$stringToAction, response.cf);
		return author$project$Page$Refund$RefundAvailable(availableOptions);
	}
};
var elm$time$Time$posixToMillis = function (_n0) {
	var millis = _n0;
	return millis;
};
var author$project$Page$Refund$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var token = msg.a;
				var result = msg.b;
				switch (result.$) {
					case 3:
						var response = result.a;
						var refundState = function () {
							var _n2 = response.ct;
							if (!_n2.$) {
								var expires = _n2.a;
								return (_Utils_cmp(
									expires,
									elm$time$Time$posixToMillis(model.a1)) < 0) ? author$project$Page$Refund$RefundExpired : author$project$Page$Refund$stateFromResponse(response);
							} else {
								return author$project$Page$Refund$stateFromResponse(response);
							}
						}();
						var eventInfo = author$project$Page$Refund$eventFromResponse(response);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c0: A3(author$project$Page$Refund$ValidToken, token, eventInfo, refundState)
								}),
							elm$core$Platform$Cmd$none);
					case 2:
						var f = result.a;
						var errorMessage = function () {
							switch (f.$) {
								case 0:
									var string = f.a;
									return string;
								case 1:
									return 'Request timed out';
								case 2:
									return 'NetworkError';
								case 3:
									var _int = f.a;
									return 'Bad Status ' + elm$core$String$fromInt(_int);
								default:
									var string = f.a;
									return string;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c0: author$project$Page$Refund$StatusCheckFailed(f)
								}),
							author$project$Page$Refund$sentry('ReceivedTokenResponse error: ' + errorMessage));
					default:
						return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 1:
				var eventInfo = msg.a;
				var action = msg.b;
				var token = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c0: A3(
								author$project$Page$Refund$ValidToken,
								token,
								eventInfo,
								A2(author$project$Page$Refund$ActionSelected, action, author$project$Page$Refund$ActionInProgress))
						}),
					A4(
						author$project$Api$Refund$doRefund,
						model.at,
						action,
						token,
						A3(author$project$Page$Refund$ReceiveRefundResponse, eventInfo, action, token)));
			case 2:
				var eventInfo = msg.a;
				var action = msg.b;
				var token = msg.c;
				var webData = msg.d;
				switch (webData.$) {
					case 3:
						var response = webData.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c0: A3(
										author$project$Page$Refund$ValidToken,
										token,
										eventInfo,
										A2(
											author$project$Page$Refund$ActionSelected,
											action,
											author$project$Page$Refund$ActionPerformed(response)))
								}),
							elm$core$Platform$Cmd$none);
					case 2:
						var e = webData.a;
						var errorMessage = function () {
							switch (e.$) {
								case 0:
									var string = e.a;
									return string;
								case 1:
									return 'Request timed out';
								case 2:
									return 'NetworkError';
								case 3:
									var _int = e.a;
									return 'Bad Status ' + elm$core$String$fromInt(_int);
								default:
									var string = e.a;
									return string;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c0: A3(
										author$project$Page$Refund$ValidToken,
										token,
										eventInfo,
										A2(
											author$project$Page$Refund$ActionSelected,
											action,
											author$project$Page$Refund$ActionFailed(e)))
								}),
							author$project$Page$Refund$sentry('ReceiveRefundResponse error: ' + errorMessage));
					default:
						return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	});
var elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var author$project$BankAccount$combine = A2(
	elm$core$List$foldr,
	elm$core$Maybe$map2(elm$core$List$cons),
	elm$core$Maybe$Just(_List_Nil));
var elm$core$String$cons = _String_cons;
var elm$core$String$fromChar = function (_char) {
	return A2(elm$core$String$cons, _char, '');
};
var elm$core$String$toInt = _String_toInt;
var author$project$BankAccount$charsToInts = function (chars) {
	return author$project$BankAccount$combine(
		A2(
			elm$core$List$map,
			elm$core$String$toInt,
			A2(elm$core$List$map, elm$core$String$fromChar, chars)));
};
var elm$core$Basics$modBy = _Basics_modBy;
var author$project$BankAccount$mod11 = elm$core$Basics$modBy(11);
var elm$core$Tuple$second = function (_n0) {
	var y = _n0.b;
	return y;
};
var author$project$BankAccount$mult = F2(
	function (t, acc) {
		return acc + (t.a * t.b);
	});
var author$project$BankAccount$normalize = function (checksum) {
	if (!checksum) {
		return 0;
	} else {
		return 11 - checksum;
	}
};
var author$project$BankAccount$weights = _List_fromArray(
	[2, 3, 4, 5, 6, 7, 2, 3, 4, 5]);
var elm$core$Basics$negate = function (n) {
	return -n;
};
var elm$core$String$slice = _String_slice;
var elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3(elm$core$String$slice, 0, -n, string);
	});
var elm$core$String$length = _String_length;
var elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			elm$core$String$slice,
			-n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$foldr = _String_foldr;
var elm$core$String$toList = function (string) {
	return A3(elm$core$String$foldr, elm$core$List$cons, _List_Nil, string);
};
var elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var author$project$BankAccount$validate = function (accountNumber) {
	var _n0 = elm$core$String$length(accountNumber);
	if (_n0 === 11) {
		var controlNumber = elm$core$String$toInt(
			A2(elm$core$String$right, 1, accountNumber));
		var accountIntegers = author$project$BankAccount$charsToInts(
			elm$core$List$reverse(
				elm$core$String$toList(
					A2(elm$core$String$dropRight, 1, accountNumber))));
		var checksum = function () {
			if (accountIntegers.$ === 1) {
				return elm$core$Maybe$Nothing;
			} else {
				var xs = accountIntegers.a;
				return elm$core$Maybe$Just(
					author$project$BankAccount$normalize(
						author$project$BankAccount$mod11(
							A3(
								elm$core$List$foldl,
								author$project$BankAccount$mult,
								0,
								A3(elm$core$List$map2, elm$core$Tuple$pair, xs, author$project$BankAccount$weights)))));
			}
		}();
		var _n1 = _Utils_Tuple2(controlNumber, checksum);
		if ((!_n1.a.$) && (!_n1.b.$)) {
			var control = _n1.a.a;
			var check = _n1.b.a;
			return _Utils_eq(control, check);
		} else {
			return false;
		}
	} else {
		return false;
	}
};
var author$project$Page$Remittance$EventRefund = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Remittance$Invalid = 2;
var author$project$Page$Remittance$StatusError = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Remittance$SubmitFailed = 5;
var author$project$Page$Remittance$SubmitSuccess = 4;
var author$project$Page$Remittance$Submitted = 3;
var author$project$Page$Remittance$SubscriptionRefund = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Remittance$Success = F5(
	function (a, b, c, d, e) {
		return {$: 1, a: a, b: b, c: c, d: d, e: e};
	});
var author$project$Page$Remittance$ReceivedSubmitResponse = function (a) {
	return {$: 5, a: a};
};
var author$project$Page$Remittance$SubmitResponse = F2(
	function (status, error) {
		return {co: error, c0: status};
	});
var author$project$Page$Remittance$decodeSubmitResponse = A4(
	NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'error',
	elm$json$Json$Decode$nullable(elm$json$Json$Decode$string),
	elm$core$Maybe$Nothing,
	A3(
		NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		elm$json$Json$Decode$string,
		elm$json$Json$Decode$succeed(author$project$Page$Remittance$SubmitResponse)));
var author$project$Page$Remittance$encodeData = F2(
	function (name, account) {
		return elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'accountName',
					elm$json$Json$Encode$string(name)),
					_Utils_Tuple2(
					'accountNumber',
					elm$json$Json$Encode$string(account))
				]));
	});
var author$project$Page$Remittance$submit = F3(
	function (token, name, account) {
		return elm$http$Http$post(
			{
				cg: elm$http$Http$jsonBody(
					A2(author$project$Page$Remittance$encodeData, name, account)),
				cr: A2(elm$http$Http$expectJson, author$project$Page$Remittance$ReceivedSubmitResponse, author$project$Page$Remittance$decodeSubmitResponse),
				b6: author$project$Page$Remittance$baseUrl + ('/refund/' + (token + '/submit'))
			});
	});
var author$project$Page$Remittance$Dirty = 1;
var elm$core$String$filter = _String_filter;
var author$project$Page$Remittance$updateFormModel = F3(
	function (model, input, value) {
		if (!input) {
			return _Utils_update(
				model,
				{r: 1, cK: value});
		} else {
			var _n1 = model.v;
			var state = _n1.a;
			return _Utils_update(
				model,
				{
					v: A2(
						author$project$Page$Remittance$Account,
						state,
						A2(elm$core$String$filter, elm$core$Char$isDigit, value)),
					r: 1
				});
		}
	});
var author$project$Page$Remittance$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var input = msg.a;
				var value = msg.b;
				return _Utils_Tuple2(
					A3(author$project$Page$Remittance$updateFormModel, model, input, value),
					elm$core$Platform$Cmd$none);
			case 3:
				var state = msg.a;
				var _n1 = model.v;
				var value = _n1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: A2(author$project$Page$Remittance$Account, state, value)
						}),
					elm$core$Platform$Cmd$none);
			case 0:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			case 1:
				var response = msg.a;
				if (!response.$) {
					var data = response.a;
					if (!data.$) {
						var r = data.a;
						var buyer = function () {
							var _n4 = r.ao;
							if (_n4.$ === 1) {
								return author$project$Page$Remittance$TicketBuyer('');
							} else {
								var b = _n4.a;
								return b;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cK: buyer.cK,
									N: A5(
										author$project$Page$Remittance$Success,
										author$project$Page$Remittance$EventRefund(r.cp),
										buyer,
										r.cP,
										r.ak,
										r.al)
								}),
							elm$core$Platform$Cmd$none);
					} else {
						var r = data.a;
						var buyer = function () {
							var _n5 = r.ao;
							if (_n5.$ === 1) {
								return author$project$Page$Remittance$TicketBuyer('');
							} else {
								var b = _n5.a;
								return b;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cK: buyer.cK,
									N: A5(
										author$project$Page$Remittance$Success,
										author$project$Page$Remittance$SubscriptionRefund(r.b0),
										buyer,
										r.cP,
										r.ak,
										r.al)
								}),
							elm$core$Platform$Cmd$none);
					}
				} else {
					var e = response.a;
					var errorMessage = function () {
						switch (e.$) {
							case 0:
								var string = e.a;
								return string;
							case 1:
								return 'Request timed out';
							case 2:
								return 'NetworkError';
							case 3:
								var _int = e.a;
								return 'Bad Status ' + elm$core$String$fromInt(_int);
							default:
								var string = e.a;
								return string;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								N: author$project$Page$Remittance$StatusError(e)
							}),
						author$project$Page$Refund$sentry(errorMessage));
				}
			case 4:
				var _n7 = model.cK;
				var name = _n7;
				var _n8 = model.v;
				var account = _n8.b;
				return author$project$BankAccount$validate(account) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{r: 3}),
					A3(author$project$Page$Remittance$submit, model.aS, name, account)) : _Utils_Tuple2(
					_Utils_update(
						model,
						{r: 2}),
					elm$core$Platform$Cmd$none);
			default:
				var response = msg.a;
				if (!response.$) {
					var data = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{r: 4}),
						elm$core$Platform$Cmd$none);
				} else {
					var e = response.a;
					var errorMessage = function () {
						switch (e.$) {
							case 0:
								var string = e.a;
								return string;
							case 1:
								return 'Request timed out';
							case 2:
								return 'NetworkError';
							case 3:
								var _int = e.a;
								return 'Bad Status ' + elm$core$String$fromInt(_int);
							default:
								var string = e.a;
								return string;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{r: 5}),
						author$project$Page$Refund$sentry(errorMessage));
				}
		}
	});
var elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Dom$NotFound = elm$core$Basics$identity;
var elm$core$Basics$never = function (_n0) {
	never:
	while (true) {
		var nvr = _n0;
		var $temp$_n0 = nvr;
		_n0 = $temp$_n0;
		continue never;
	}
};
var elm$core$Task$Perform = elm$core$Basics$identity;
var elm$core$Task$init = elm$core$Task$succeed(0);
var elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var elm$core$Task$spawnCmd = F2(
	function (router, _n0) {
		var task = _n0;
		return _Scheduler_spawn(
			A2(
				elm$core$Task$andThen,
				elm$core$Platform$sendToApp(router),
				task));
	});
var elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			elm$core$Task$map,
			function (_n0) {
				return 0;
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Task$spawnCmd(router),
					commands)));
	});
var elm$core$Task$onSelfMsg = F3(
	function (_n0, _n1, _n2) {
		return elm$core$Task$succeed(0);
	});
var elm$core$Task$cmdMap = F2(
	function (tagger, _n0) {
		var task = _n0;
		return A2(elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager(elm$core$Task$init, elm$core$Task$onEffects, elm$core$Task$onSelfMsg, elm$core$Task$cmdMap);
var elm$core$Task$command = _Platform_leaf('Task');
var elm$core$Task$perform = F2(
	function (toMessage, task) {
		return elm$core$Task$command(
			A2(elm$core$Task$map, toMessage, task));
	});
var elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			elm$core$String$slice,
			n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$startsWith = _String_startsWith;
var elm$url$Url$Http = 0;
var elm$url$Url$Https = 1;
var elm$core$String$indexes = _String_indexes;
var elm$core$String$isEmpty = function (string) {
	return string === '';
};
var elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(elm$core$String$slice, 0, n, string);
	});
var elm$core$String$contains = _String_contains;
var elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {a9: fragment, bd: host, bz: path, bD: port_, bK: protocol, bL: query};
	});
var elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if (elm$core$String$isEmpty(str) || A2(elm$core$String$contains, '@', str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, ':', str);
			if (!_n0.b) {
				return elm$core$Maybe$Just(
					A6(elm$url$Url$Url, protocol, str, elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_n0.b.b) {
					var i = _n0.a;
					var _n1 = elm$core$String$toInt(
						A2(elm$core$String$dropLeft, i + 1, str));
					if (_n1.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var port_ = _n1;
						return elm$core$Maybe$Just(
							A6(
								elm$url$Url$Url,
								protocol,
								A2(elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			}
		}
	});
var elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '/', str);
			if (!_n0.b) {
				return A5(elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _n0.a;
				return A5(
					elm$url$Url$chompBeforePath,
					protocol,
					A2(elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '?', str);
			if (!_n0.b) {
				return A4(elm$url$Url$chompBeforeQuery, protocol, elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _n0.a;
				return A4(
					elm$url$Url$chompBeforeQuery,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '#', str);
			if (!_n0.b) {
				return A3(elm$url$Url$chompBeforeFragment, protocol, elm$core$Maybe$Nothing, str);
			} else {
				var i = _n0.a;
				return A3(
					elm$url$Url$chompBeforeFragment,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$fromString = function (str) {
	return A2(elm$core$String$startsWith, 'http://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		0,
		A2(elm$core$String$dropLeft, 7, str)) : (A2(elm$core$String$startsWith, 'https://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		1,
		A2(elm$core$String$dropLeft, 8, str)) : elm$core$Maybe$Nothing);
};
var elm$browser$Browser$Navigation$load = _Browser_load;
var elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + elm$core$String$fromInt(port_));
		}
	});
var elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var elm$url$Url$toString = function (url) {
	var http = function () {
		var _n0 = url.bK;
		if (!_n0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		elm$url$Url$addPrefixed,
		'#',
		url.a9,
		A3(
			elm$url$Url$addPrefixed,
			'?',
			url.bL,
			_Utils_ap(
				A2(
					elm$url$Url$addPort,
					url.bD,
					_Utils_ap(http, url.bd)),
				url.bz)));
};
var author$project$Main$update = F2(
	function (msg, model) {
		var _n0 = _Utils_Tuple2(msg, model.C);
		_n0$4:
		while (true) {
			switch (_n0.a.$) {
				case 1:
					var url = _n0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ai: author$project$Routes$fromUrl(url)
							}),
						elm$core$Platform$Cmd$none);
				case 2:
					var urlRequest = _n0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ai: author$project$Routes$fromUrl(url)
								}),
							A2(
								elm$browser$Browser$Navigation$pushUrl,
								author$project$Auth$navKey(model.aU),
								elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							elm$browser$Browser$Navigation$load(href));
					}
				case 3:
					if (!_n0.b.$) {
						var subMsg = _n0.a.a;
						var subModel = _n0.b.a;
						var _n2 = A3(
							author$project$Main$updateWith,
							author$project$Main$RefundModel,
							author$project$Main$GotRefundMsg,
							A2(author$project$Page$Refund$update, subMsg, subModel));
						var sm = _n2.a;
						var subCmd = _n2.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{C: sm}),
							subCmd);
					} else {
						break _n0$4;
					}
				case 5:
					if (_n0.b.$ === 2) {
						var subMsg = _n0.a.a;
						var subModel = _n0.b.a;
						var _n3 = A3(
							author$project$Main$updateWith,
							author$project$Main$RemittanceModel,
							author$project$Main$GotRemittanceMsg,
							A2(author$project$Page$Remittance$update, subMsg, subModel));
						var sm = _n3.a;
						var subCmd = _n3.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{C: sm}),
							subCmd);
					} else {
						break _n0$4;
					}
				default:
					break _n0$4;
			}
		}
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var elm$html$Html$div = _VirtualDom_node('div');
var elm$html$Html$p = _VirtualDom_node('p');
var elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var elm$html$Html$text = elm$virtual_dom$VirtualDom$text;
var author$project$Page$NoToken$view = function (model) {
	return A2(
		elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text('No token in url')
					]))
			]));
};
var elm$html$Html$section = _VirtualDom_node('section');
var elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$string(string));
	});
var elm$html$Html$Attributes$class = elm$html$Html$Attributes$stringProperty('className');
var author$project$Layout$viewSection = function (content) {
	return A2(
		elm$html$Html$section,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('section')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('container')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('content')
							]),
						content)
					]))
			]));
};
var elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$utc = A2(elm$time$Time$Zone, 0, _List_Nil);
var elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {a0: col, bH: problem, bR: row};
	});
var elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3(elm$parser$Parser$DeadEnd, p.bR, p.a0, p.bH);
};
var elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2(elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2(elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var elm$parser$Parser$Advanced$run = F2(
	function (_n0, src) {
		var parse = _n0;
		var _n1 = parse(
			{a0: 1, c: _List_Nil, d: 1, b: 0, bR: 1, a: src});
		if (!_n1.$) {
			var value = _n1.b;
			return elm$core$Result$Ok(value);
		} else {
			var bag = _n1.b;
			return elm$core$Result$Err(
				A2(elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var elm$parser$Parser$run = F2(
	function (parser, source) {
		var _n0 = A2(elm$parser$Parser$Advanced$run, parser, source);
		if (!_n0.$) {
			var a = _n0.a;
			return elm$core$Result$Ok(a);
		} else {
			var problems = _n0.a;
			return elm$core$Result$Err(
				A2(elm$core$List$map, elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var elm$parser$Parser$Advanced$Parser = elm$core$Basics$identity;
var elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _n0) {
		var parseA = _n0;
		return function (s0) {
			var _n1 = parseA(s0);
			if (_n1.$ === 1) {
				var p = _n1.a;
				var x = _n1.b;
				return A2(elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _n1.a;
				var a = _n1.b;
				var s1 = _n1.c;
				var _n2 = callback(a);
				var parseB = _n2;
				var _n3 = parseB(s1);
				if (_n3.$ === 1) {
					var p2 = _n3.a;
					var x = _n3.b;
					return A2(elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _n3.a;
					var b = _n3.b;
					var s2 = _n3.c;
					return A3(elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var elm$parser$Parser$andThen = elm$parser$Parser$Advanced$andThen;
var elm$parser$Parser$ExpectingEnd = {$: 10};
var elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {a0: col, ci: contextStack, bH: problem, bR: row};
	});
var elm$parser$Parser$Advanced$Empty = {$: 0};
var elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			elm$parser$Parser$Advanced$AddRight,
			elm$parser$Parser$Advanced$Empty,
			A4(elm$parser$Parser$Advanced$DeadEnd, s.bR, s.a0, x, s.c));
	});
var elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			elm$core$String$length(s.a),
			s.b) ? A3(elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			elm$parser$Parser$Advanced$Bad,
			false,
			A2(elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var elm$parser$Parser$end = elm$parser$Parser$Advanced$end(elm$parser$Parser$ExpectingEnd);
var elm$core$Basics$always = F2(
	function (a, _n0) {
		return a;
	});
var elm$parser$Parser$Advanced$map2 = F3(
	function (func, _n0, _n1) {
		var parseA = _n0;
		var parseB = _n1;
		return function (s0) {
			var _n2 = parseA(s0);
			if (_n2.$ === 1) {
				var p = _n2.a;
				var x = _n2.b;
				return A2(elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _n2.a;
				var a = _n2.b;
				var s1 = _n2.c;
				var _n3 = parseB(s1);
				if (_n3.$ === 1) {
					var p2 = _n3.a;
					var x = _n3.b;
					return A2(elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _n3.a;
					var b = _n3.b;
					var s2 = _n3.c;
					return A3(
						elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3(elm$parser$Parser$Advanced$map2, elm$core$Basics$always, keepParser, ignoreParser);
	});
var elm$parser$Parser$ignorer = elm$parser$Parser$Advanced$ignorer;
var elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3(elm$parser$Parser$Advanced$map2, elm$core$Basics$apL, parseFunc, parseArg);
	});
var elm$parser$Parser$keeper = elm$parser$Parser$Advanced$keeper;
var elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2(elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _n1 = parse(s0);
				if (!_n1.$) {
					var step = _n1;
					return step;
				} else {
					var step = _n1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2(elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3(elm$parser$Parser$Advanced$oneOfHelp, s, elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var elm$parser$Parser$oneOf = elm$parser$Parser$Advanced$oneOf;
var elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3(elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var elm$parser$Parser$succeed = elm$parser$Parser$Advanced$succeed;
var elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$core$Basics$not = _Basics_not;
var elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var elm$parser$Parser$Advanced$token = function (_n0) {
	var str = _n0.a;
	var expecting = _n0.b;
	var progress = !elm$core$String$isEmpty(str);
	return function (s) {
		var _n1 = A5(elm$parser$Parser$Advanced$isSubString, str, s.b, s.bR, s.a0, s.a);
		var newOffset = _n1.a;
		var newRow = _n1.b;
		var newCol = _n1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			elm$parser$Parser$Advanced$Bad,
			false,
			A2(elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{a0: newCol, c: s.c, d: s.d, b: newOffset, bR: newRow, a: s.a});
	};
};
var elm$parser$Parser$Advanced$symbol = elm$parser$Parser$Advanced$token;
var elm$parser$Parser$symbol = function (str) {
	return elm$parser$Parser$Advanced$symbol(
		A2(
			elm$parser$Parser$Advanced$Token,
			str,
			elm$parser$Parser$ExpectingSymbol(str)));
};
var elm$core$Basics$round = _Basics_round;
var elm$core$String$toFloat = _String_toFloat;
var elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3(elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{a0: col, c: s0.c, d: s0.d, b: offset, bR: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5(elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.bR, s.a0, s);
	};
};
var elm$parser$Parser$chompWhile = elm$parser$Parser$Advanced$chompWhile;
var elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _n0) {
		var parse = _n0;
		return function (s0) {
			var _n1 = parse(s0);
			if (_n1.$ === 1) {
				var p = _n1.a;
				var x = _n1.b;
				return A2(elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _n1.a;
				var a = _n1.b;
				var s1 = _n1.c;
				return A3(
					elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3(elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2(elm$parser$Parser$Advanced$mapChompedString, elm$core$Basics$always, parser);
};
var elm$parser$Parser$getChompedString = elm$parser$Parser$Advanced$getChompedString;
var elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			elm$parser$Parser$Advanced$Bad,
			false,
			A2(elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var elm$parser$Parser$problem = function (msg) {
	return elm$parser$Parser$Advanced$problem(
		elm$parser$Parser$Problem(msg));
};
var rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	elm$parser$Parser$andThen,
	function (str) {
		if (elm$core$String$length(str) <= 9) {
			var _n0 = elm$core$String$toFloat('0.' + str);
			if (!_n0.$) {
				var floatVal = _n0.a;
				return elm$parser$Parser$succeed(
					elm$core$Basics$round(floatVal * 1000));
			} else {
				return elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + elm$core$String$fromInt(
					elm$core$String$length(str)));
		}
	},
	elm$parser$Parser$getChompedString(
		elm$parser$Parser$chompWhile(elm$core$Char$isDigit)));
var rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var elm$core$String$append = _String_append;
var elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var elm$parser$Parser$UnexpectedChar = {$: 11};
var elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3(elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				elm$parser$Parser$Advanced$Bad,
				false,
				A2(elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				elm$parser$Parser$Advanced$Good,
				true,
				0,
				{a0: 1, c: s.c, d: s.d, b: s.b + 1, bR: s.bR + 1, a: s.a}) : A3(
				elm$parser$Parser$Advanced$Good,
				true,
				0,
				{a0: s.a0 + 1, c: s.c, d: s.d, b: newOffset, bR: s.bR, a: s.a}));
		};
	});
var elm$parser$Parser$chompIf = function (isGood) {
	return A2(elm$parser$Parser$Advanced$chompIf, isGood, elm$parser$Parser$UnexpectedChar);
};
var elm$parser$Parser$Advanced$map = F2(
	function (func, _n0) {
		var parse = _n0;
		return function (s0) {
			var _n1 = parse(s0);
			if (!_n1.$) {
				var p = _n1.a;
				var a = _n1.b;
				var s1 = _n1.c;
				return A3(
					elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _n1.a;
				var x = _n1.b;
				return A2(elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var elm$parser$Parser$map = elm$parser$Parser$Advanced$map;
var elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return elm$parser$Parser$Advanced$Done(a);
	}
};
var elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _n0 = callback(state);
			var parse = _n0;
			var _n1 = parse(s0);
			if (!_n1.$) {
				var p1 = _n1.a;
				var step = _n1.b;
				var s1 = _n1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3(elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _n1.a;
				var x = _n1.b;
				return A2(elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4(elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					elm$parser$Parser$map,
					elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			elm$core$String$length(str),
			quantity)) {
			var _n0 = elm$core$String$toInt(str);
			if (!_n0.$) {
				var intVal = _n0.a;
				return A2(
					elm$parser$Parser$map,
					elm$parser$Parser$Done,
					elm$parser$Parser$succeed(intVal));
			} else {
				return elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				elm$parser$Parser$map,
				function (nextChar) {
					return elm$parser$Parser$Loop(
						A2(elm$core$String$append, str, nextChar));
				},
				elm$parser$Parser$getChompedString(
					elm$parser$Parser$chompIf(elm$core$Char$isDigit)));
		}
	};
	return A2(elm$parser$Parser$loop, '', helper);
};
var rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return elm$parser$Parser$problem(
		'Invalid day: ' + elm$core$String$fromInt(day));
};
var elm$core$Basics$neq = _Utils_notEqual;
var rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2(elm$core$Basics$modBy, 4, year)) && (A2(elm$core$Basics$modBy, 100, year) || (!A2(elm$core$Basics$modBy, 400, year)));
};
var rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_n0) {
	var year = _n0.a;
	var month = _n0.b;
	var dayInMonth = _n0.c;
	if (dayInMonth < 0) {
		return rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + (rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return elm$parser$Parser$problem(
					'Invalid month: \"' + (elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	elm$parser$Parser$andThen,
	rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		elm$parser$Parser$keeper,
		A2(
			elm$parser$Parser$keeper,
			A2(
				elm$parser$Parser$keeper,
				elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						elm$parser$Parser$keeper,
						A2(
							elm$parser$Parser$ignorer,
							elm$parser$Parser$succeed(elm$core$Basics$identity),
							elm$parser$Parser$symbol('-')),
						rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					elm$parser$Parser$keeper,
					A2(
						elm$parser$Parser$ignorer,
						elm$parser$Parser$succeed(elm$core$Basics$identity),
						elm$parser$Parser$symbol('-')),
					rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		elm$parser$Parser$keeper,
		elm$parser$Parser$succeed(elm$core$Basics$identity),
		elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					elm$parser$Parser$map,
					function (_n0) {
						return 0;
					},
					elm$parser$Parser$symbol('Z')),
					A2(
					elm$parser$Parser$keeper,
					A2(
						elm$parser$Parser$keeper,
						A2(
							elm$parser$Parser$keeper,
							elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										elm$parser$Parser$map,
										function (_n1) {
											return 1;
										},
										elm$parser$Parser$symbol('+')),
										A2(
										elm$parser$Parser$map,
										function (_n2) {
											return -1;
										},
										elm$parser$Parser$symbol('-'))
									]))),
						rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								elm$parser$Parser$keeper,
								A2(
									elm$parser$Parser$ignorer,
									elm$parser$Parser$succeed(elm$core$Basics$identity),
									elm$parser$Parser$symbol(':')),
								rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								elm$parser$Parser$succeed(0)
							]))),
					A2(
					elm$parser$Parser$ignorer,
					elm$parser$Parser$succeed(0),
					elm$parser$Parser$end)
				])));
}();
var rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	elm$parser$Parser$andThen,
	function (datePart) {
		return elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					elm$parser$Parser$keeper,
					A2(
						elm$parser$Parser$keeper,
						A2(
							elm$parser$Parser$keeper,
							A2(
								elm$parser$Parser$keeper,
								A2(
									elm$parser$Parser$keeper,
									A2(
										elm$parser$Parser$ignorer,
										elm$parser$Parser$succeed(
											rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										elm$parser$Parser$symbol('T')),
									rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											elm$parser$Parser$keeper,
											A2(
												elm$parser$Parser$ignorer,
												elm$parser$Parser$succeed(elm$core$Basics$identity),
												elm$parser$Parser$symbol(':')),
											rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										elm$parser$Parser$keeper,
										A2(
											elm$parser$Parser$ignorer,
											elm$parser$Parser$succeed(elm$core$Basics$identity),
											elm$parser$Parser$symbol(':')),
										rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									elm$parser$Parser$keeper,
									A2(
										elm$parser$Parser$ignorer,
										elm$parser$Parser$succeed(elm$core$Basics$identity),
										elm$parser$Parser$symbol('.')),
									rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									elm$parser$Parser$succeed(0)
								]))),
					A2(elm$parser$Parser$ignorer, rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, elm$parser$Parser$end)),
					A2(
					elm$parser$Parser$ignorer,
					elm$parser$Parser$succeed(
						A6(rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					elm$parser$Parser$end)
				]));
	},
	rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2(elm$parser$Parser$run, rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var ryannhg$date_format$DateFormat$DayOfMonthFixed = {$: 7};
var ryannhg$date_format$DateFormat$dayOfMonthFixed = ryannhg$date_format$DateFormat$DayOfMonthFixed;
var elm$core$String$toLower = _String_toLower;
var elm$core$String$toUpper = _String_toUpper;
var elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return elm$core$Basics$floor(numerator / denominator);
	});
var elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.aR, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var elm$time$Time$toAdjustedMinutes = F2(
	function (_n0, time) {
		var defaultOffset = _n0.a;
		var eras = _n0.b;
		return A3(
			elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				elm$time$Time$flooredDiv,
				elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			elm$core$Basics$modBy,
			24,
			A2(
				elm$time$Time$flooredDiv,
				A2(elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var elm$time$Time$toMillis = F2(
	function (_n0, time) {
		return A2(
			elm$core$Basics$modBy,
			1000,
			elm$time$Time$posixToMillis(time));
	});
var elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			elm$core$Basics$modBy,
			60,
			A2(elm$time$Time$toAdjustedMinutes, zone, time));
	});
var elm$time$Time$Apr = 3;
var elm$time$Time$Aug = 7;
var elm$time$Time$Dec = 11;
var elm$time$Time$Feb = 1;
var elm$time$Time$Jan = 0;
var elm$time$Time$Jul = 6;
var elm$time$Time$Jun = 5;
var elm$time$Time$Mar = 2;
var elm$time$Time$May = 4;
var elm$time$Time$Nov = 10;
var elm$time$Time$Oct = 9;
var elm$time$Time$Sep = 8;
var elm$core$Basics$ge = _Utils_ge;
var elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2(elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		a2: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		bp: month,
		b8: year + ((month <= 2) ? 1 : 0)
	};
};
var elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _n0 = elm$time$Time$toCivil(
			A2(elm$time$Time$toAdjustedMinutes, zone, time)).bp;
		switch (_n0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var elm$time$Time$toSecond = F2(
	function (_n0, time) {
		return A2(
			elm$core$Basics$modBy,
			60,
			A2(
				elm$time$Time$flooredDiv,
				elm$time$Time$posixToMillis(time),
				1000));
	});
var elm$time$Time$Fri = 4;
var elm$time$Time$Mon = 0;
var elm$time$Time$Sat = 5;
var elm$time$Time$Sun = 6;
var elm$time$Time$Thu = 3;
var elm$time$Time$Tue = 1;
var elm$time$Time$Wed = 2;
var elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _n0 = A2(
			elm$core$Basics$modBy,
			7,
			A2(
				elm$time$Time$flooredDiv,
				A2(elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_n0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var ryannhg$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.c6(
			A2(elm$time$Time$toHour, zone, posix));
	});
var elm$time$Time$toDay = F2(
	function (zone, time) {
		return elm$time$Time$toCivil(
			A2(elm$time$Time$toAdjustedMinutes, zone, time)).a2;
	});
var ryannhg$date_format$DateFormat$dayOfMonth = elm$time$Time$toDay;
var elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(x);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var ryannhg$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var ryannhg$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_n1) {
			var i = _n1.a;
			return i;
		}(
			A2(
				elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				elm$core$List$head(
					A2(
						elm$core$List$filter,
						function (_n0) {
							var day = _n0.b;
							return _Utils_eq(
								day,
								A2(elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							ryannhg$date_format$DateFormat$days)))));
	});
var elm$core$List$sum = function (numbers) {
	return A3(elm$core$List$foldl, elm$core$Basics$add, 0, numbers);
};
var elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2(elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var elm$core$List$takeTailRec = F2(
	function (n, list) {
		return elm$core$List$reverse(
			A3(elm$core$List$takeReverse, n, list, _List_Nil));
	});
var elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _n0 = _Utils_Tuple2(n, list);
			_n0$1:
			while (true) {
				_n0$5:
				while (true) {
					if (!_n0.b.b) {
						return list;
					} else {
						if (_n0.b.b.b) {
							switch (_n0.a) {
								case 1:
									break _n0$1;
								case 2:
									var _n2 = _n0.b;
									var x = _n2.a;
									var _n3 = _n2.b;
									var y = _n3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_n0.b.b.b.b) {
										var _n4 = _n0.b;
										var x = _n4.a;
										var _n5 = _n4.b;
										var y = _n5.a;
										var _n6 = _n5.b;
										var z = _n6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _n0$5;
									}
								default:
									if (_n0.b.b.b.b && _n0.b.b.b.b.b) {
										var _n7 = _n0.b;
										var x = _n7.a;
										var _n8 = _n7.b;
										var y = _n8.a;
										var _n9 = _n8.b;
										var z = _n9.a;
										var _n10 = _n9.b;
										var w = _n10.a;
										var tl = _n10.b;
										return (ctr > 1000) ? A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A2(elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A3(elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _n0$5;
									}
							}
						} else {
							if (_n0.a === 1) {
								break _n0$1;
							} else {
								break _n0$5;
							}
						}
					}
				}
				return list;
			}
			var _n1 = _n0.b;
			var x = _n1.a;
			return _List_fromArray(
				[x]);
		}
	});
var elm$core$List$take = F2(
	function (n, list) {
		return A3(elm$core$List$takeFast, 0, n, list);
	});
var elm$time$Time$toYear = F2(
	function (zone, time) {
		return elm$time$Time$toCivil(
			A2(elm$time$Time$toAdjustedMinutes, zone, time)).b8;
	});
var ryannhg$date_format$DateFormat$isLeapYear = function (year_) {
	return A2(elm$core$Basics$modBy, 4, year_) ? false : (A2(elm$core$Basics$modBy, 100, year_) ? true : (A2(elm$core$Basics$modBy, 400, year_) ? false : true));
};
var ryannhg$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return ryannhg$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var ryannhg$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var ryannhg$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			elm$core$List$head(
				A2(
					elm$core$List$filter,
					function (_n0) {
						var i = _n0.a;
						var m = _n0.b;
						return _Utils_eq(
							m,
							A2(elm$time$Time$toMonth, zone, posix));
					},
					A2(
						elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						ryannhg$date_format$DateFormat$months))));
	});
var ryannhg$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_n0) {
			var i = _n0.a;
			var m = _n0.b;
			return i;
		}(
			A2(ryannhg$date_format$DateFormat$monthPair, zone, posix));
	});
var ryannhg$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			elm$core$List$take,
			A2(ryannhg$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			ryannhg$date_format$DateFormat$months);
		var daysBeforeThisMonth = elm$core$List$sum(
			A2(
				elm$core$List$map,
				ryannhg$date_format$DateFormat$daysInMonth(
					A2(elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2(ryannhg$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var ryannhg$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2(ryannhg$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var ryannhg$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - elm$core$String$length(numStr);
		var zeros = A2(
			elm$core$String$join,
			'',
			A2(
				elm$core$List$map,
				function (_n0) {
					return '0';
				},
				A2(elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var ryannhg$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var ryannhg$date_format$DateFormat$millisecondsPerYear = elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var ryannhg$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return elm$time$Time$millisToPosix(
			ryannhg$date_format$DateFormat$millisecondsPerYear * A2(elm$time$Time$toYear, zone, time));
	});
var ryannhg$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2(ryannhg$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2(ryannhg$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2(ryannhg$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var ryannhg$date_format$DateFormat$year = F2(
	function (zone, time) {
		return elm$core$String$fromInt(
			A2(elm$time$Time$toYear, zone, time));
	});
var ryannhg$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return elm$core$String$fromInt(
					A2(ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						elm$core$String$fromInt(num),
						language.M(num));
				}(
					A2(ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2(ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.c7(
					A2(elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.c8(
					A2(elm$time$Time$toMonth, zone, posix));
			case 17:
				return elm$core$String$fromInt(
					1 + A2(ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						elm$core$String$fromInt(num),
						language.M(num));
				}(
					1 + A2(ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return elm$core$String$fromInt(
					A2(ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						elm$core$String$fromInt(num),
						language.M(num));
				}(
					A2(ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2(ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return elm$core$String$fromInt(
					A2(ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						elm$core$String$fromInt(num),
						language.M(num));
				}(
					A2(ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2(ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return elm$core$String$fromInt(
					A2(ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						elm$core$String$fromInt(num),
						language.M(num));
				}(
					A2(ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.c9(
					A2(elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.da(
					A2(elm$time$Time$toWeekday, zone, posix));
			case 19:
				return elm$core$String$fromInt(
					A2(ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						elm$core$String$fromInt(num),
						language.M(num));
				}(
					A2(ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2(ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					elm$core$String$right,
					2,
					A2(ryannhg$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2(ryannhg$date_format$DateFormat$year, zone, posix);
			case 22:
				return elm$core$String$toUpper(
					A3(ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return elm$core$String$toLower(
					A3(ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return elm$core$String$fromInt(
					A2(elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2(elm$time$Time$toHour, zone, posix));
			case 26:
				return elm$core$String$fromInt(
					ryannhg$date_format$DateFormat$toNonMilitary(
						A2(elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					ryannhg$date_format$DateFormat$toNonMilitary(
						A2(elm$time$Time$toHour, zone, posix)));
			case 28:
				return elm$core$String$fromInt(
					1 + A2(elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					1 + A2(elm$time$Time$toHour, zone, posix));
			case 30:
				return elm$core$String$fromInt(
					A2(elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2(elm$time$Time$toMinute, zone, posix));
			case 32:
				return elm$core$String$fromInt(
					A2(elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2(elm$time$Time$toSecond, zone, posix));
			case 34:
				return elm$core$String$fromInt(
					A2(elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2(elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var ryannhg$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			elm$core$String$join,
			'',
			A2(
				elm$core$List$map,
				A3(ryannhg$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var ryannhg$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {c6: toAmPm, c7: toMonthAbbreviation, c8: toMonthName, M: toOrdinalSuffix, c9: toWeekdayAbbreviation, da: toWeekdayName};
	});
var ryannhg$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var ryannhg$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var ryannhg$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _n0 = A2(elm$core$Basics$modBy, 100, num);
	switch (_n0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _n1 = A2(elm$core$Basics$modBy, 10, num);
			switch (_n1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var ryannhg$date_format$DateFormat$Language$english = A6(
	ryannhg$date_format$DateFormat$Language$Language,
	ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		elm$core$Basics$composeR,
		ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
		elm$core$String$left(3)),
	ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		elm$core$Basics$composeR,
		ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
		elm$core$String$left(3)),
	ryannhg$date_format$DateFormat$Language$toEnglishAmPm,
	ryannhg$date_format$DateFormat$Language$toEnglishSuffix);
var ryannhg$date_format$DateFormat$format = ryannhg$date_format$DateFormat$formatWithLanguage(ryannhg$date_format$DateFormat$Language$english);
var ryannhg$date_format$DateFormat$HourMilitaryFromOneFixed = {$: 29};
var ryannhg$date_format$DateFormat$hourMilitaryFromOneFixed = ryannhg$date_format$DateFormat$HourMilitaryFromOneFixed;
var ryannhg$date_format$DateFormat$MinuteFixed = {$: 31};
var ryannhg$date_format$DateFormat$minuteFixed = ryannhg$date_format$DateFormat$MinuteFixed;
var ryannhg$date_format$DateFormat$MonthFixed = {$: 2};
var ryannhg$date_format$DateFormat$monthFixed = ryannhg$date_format$DateFormat$MonthFixed;
var ryannhg$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var ryannhg$date_format$DateFormat$text = ryannhg$date_format$DateFormat$Text;
var ryannhg$date_format$DateFormat$YearNumber = {$: 16};
var ryannhg$date_format$DateFormat$yearNumber = ryannhg$date_format$DateFormat$YearNumber;
var author$project$Page$Refund$formatIsoDateTime = function (isoString) {
	var posix = rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(isoString);
	var datestring = function () {
		if (!posix.$) {
			var p = posix.a;
			return A3(
				ryannhg$date_format$DateFormat$format,
				_List_fromArray(
					[
						ryannhg$date_format$DateFormat$dayOfMonthFixed,
						ryannhg$date_format$DateFormat$text('.'),
						ryannhg$date_format$DateFormat$monthFixed,
						ryannhg$date_format$DateFormat$text('.'),
						ryannhg$date_format$DateFormat$yearNumber,
						ryannhg$date_format$DateFormat$text(' kl '),
						ryannhg$date_format$DateFormat$hourMilitaryFromOneFixed,
						ryannhg$date_format$DateFormat$text(':'),
						ryannhg$date_format$DateFormat$minuteFixed
					]),
				elm$time$Time$utc,
				p);
		} else {
			return '';
		}
	}();
	return datestring;
};
var elm$html$Html$h2 = _VirtualDom_node('h2');
var author$project$Page$Refund$viewEventInfo = function (eventInfo) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('event-info')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text(eventInfo.aw)
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('event-info-sub')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text(
								author$project$Page$Refund$formatIsoDateTime(eventInfo.cl))
							])),
						A2(
						elm$html$Html$p,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('partner-name has-text-right')
							]),
						_List_fromArray(
							[
								elm$html$Html$text(eventInfo.cQ)
							]))
					]))
			]));
};
var elm$html$Html$a = _VirtualDom_node('a');
var elm$html$Html$Attributes$href = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var author$project$Page$Refund$viewRefundFailure = function (failure) {
	if (failure.$ === 3) {
		var status = failure.a;
		switch (status) {
			case 404:
				return _List_fromArray(
					[
						A2(
						elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('Finner ikke billettene. Ta kontakt med support på ')
							])),
						A2(
						elm$html$Html$a,
						_List_fromArray(
							[
								elm$html$Html$Attributes$href('mailto:post@ebillett.no')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('post@ebillett.no')
							]))
					]);
			case 409:
				return _List_fromArray(
					[
						A2(
						elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('Disse billettene har allerede blitt refundert.')
							]))
					]);
			default:
				return _List_fromArray(
					[
						A2(
						elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('Det har skjedd en feil. Refunderingen har ikke blitt utført. Kontakt support hvis problemet vedvarer.')
							]))
					]);
		}
	} else {
		return _List_fromArray(
			[
				A2(
				elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text('Det har skjedd en feil. Refunderingen har ikke blitt utført. Kontakt support hvis problemet vedvarer.')
					]))
			]);
	}
};
var author$project$Page$Refund$formatIsoDate = function (isoString) {
	var posix = rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(isoString);
	var datestring = function () {
		if (!posix.$) {
			var p = posix.a;
			return A3(
				ryannhg$date_format$DateFormat$format,
				_List_fromArray(
					[
						ryannhg$date_format$DateFormat$dayOfMonthFixed,
						ryannhg$date_format$DateFormat$text('.'),
						ryannhg$date_format$DateFormat$monthFixed,
						ryannhg$date_format$DateFormat$text('.'),
						ryannhg$date_format$DateFormat$yearNumber
					]),
				elm$time$Time$utc,
				p);
		} else {
			return '';
		}
	}();
	return datestring;
};
var cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var cuducos$elm_format_number$FormatNumber$Locales$base = {
	ac: '.',
	cm: cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	bs: '−',
	bt: '',
	bE: '',
	bF: '',
	Z: '',
	ca: '',
	cb: ''
};
var cuducos$elm_format_number$FormatNumber$Locales$frenchLocale = _Utils_update(
	cuducos$elm_format_number$FormatNumber$Locales$base,
	{
		ac: ',',
		cm: cuducos$elm_format_number$FormatNumber$Locales$Exact(3),
		Z: '\u202f'
	});
var author$project$Page$Refund$moneyLocale = _Utils_update(
	cuducos$elm_format_number$FormatNumber$Locales$frenchLocale,
	{
		cm: cuducos$elm_format_number$FormatNumber$Locales$Exact(2)
	});
var cuducos$elm_format_number$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {cm: decimals, bi: integers, bx: original, aj: prefix, an: suffix};
	});
var cuducos$elm_format_number$Parser$Negative = 2;
var cuducos$elm_format_number$Parser$Positive = 0;
var cuducos$elm_format_number$Parser$Zero = 1;
var elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var elm$core$String$concat = function (strings) {
	return A2(elm$core$String$join, '', strings);
};
var cuducos$elm_format_number$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		elm$core$String$concat(
			A2(
				elm$core$List$append,
				formatted.bi,
				elm$core$List$singleton(formatted.cm))));
	return onlyZeros ? 1 : ((formatted.bx < 0) ? 2 : 0);
};
var elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var elm$core$Bitwise$and = _Bitwise_and;
var elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3(elm$core$String$repeatHelp, n, chunk, '');
	});
var cuducos$elm_format_number$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2(elm$core$String$repeat, missing, '0'));
	});
var cuducos$elm_format_number$Parser$removeZeros = function (decimals) {
	return (A2(elm$core$String$right, 1, decimals) !== '0') ? decimals : cuducos$elm_format_number$Parser$removeZeros(
		A2(elm$core$String$dropRight, 1, decimals));
};
var cuducos$elm_format_number$Parser$getDecimals = F2(
	function (locale, digits) {
		var _n0 = locale.cm;
		switch (_n0.$) {
			case 1:
				return cuducos$elm_format_number$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _n0.a;
				return A2(cuducos$elm_format_number$Parser$addZerosToFit, min, digits);
		}
	});
var elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(xs);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$String$fromFloat = _String_fromNumber;
var elm$core$Basics$isInfinite = _Basics_isInfinite;
var elm$core$Basics$isNaN = _Basics_isNaN;
var elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return elm$core$Maybe$Just(
				f(value));
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				elm$core$String$repeat,
				n - elm$core$String$length(string),
				elm$core$String$fromChar(_char)));
	});
var elm$core$String$reverse = _String_reverse;
var elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var elm$core$Char$fromCode = _Char_fromCode;
var myrho$elm_round$Round$increaseNum = function (_n0) {
	var head = _n0.a;
	var tail = _n0.b;
	if (head === '9') {
		var _n1 = elm$core$String$uncons(tail);
		if (_n1.$ === 1) {
			return '01';
		} else {
			var headtail = _n1.a;
			return A2(
				elm$core$String$cons,
				'0',
				myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			elm$core$String$cons,
			elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var myrho$elm_round$Round$splitComma = function (str) {
	var _n0 = A2(elm$core$String$split, '.', str);
	if (_n0.b) {
		if (_n0.b.b) {
			var before = _n0.a;
			var _n1 = _n0.b;
			var after = _n1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _n0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var elm$core$Tuple$mapFirst = F2(
	function (func, _n0) {
		var x = _n0.a;
		var y = _n0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var myrho$elm_round$Round$toDecimal = function (fl) {
	var _n0 = A2(
		elm$core$String$split,
		'e',
		elm$core$String$fromFloat(
			elm$core$Basics$abs(fl)));
	if (_n0.b) {
		if (_n0.b.b) {
			var num = _n0.a;
			var _n1 = _n0.b;
			var exp = _n1.a;
			var e = A2(
				elm$core$Maybe$withDefault,
				0,
				elm$core$String$toInt(
					A2(elm$core$String$startsWith, '+', exp) ? A2(elm$core$String$dropLeft, 1, exp) : exp));
			var _n2 = myrho$elm_round$Round$splitComma(num);
			var before = _n2.a;
			var after = _n2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				elm$core$Maybe$withDefault,
				'0',
				A2(
					elm$core$Maybe$map,
					function (_n3) {
						var a = _n3.a;
						var b = _n3.b;
						return a + ('.' + b);
					},
					A2(
						elm$core$Maybe$map,
						elm$core$Tuple$mapFirst(elm$core$String$fromChar),
						elm$core$String$uncons(
							_Utils_ap(
								A2(
									elm$core$String$repeat,
									elm$core$Basics$abs(e),
									'0'),
								total))))) : A3(elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _n0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if (elm$core$Basics$isInfinite(fl) || elm$core$Basics$isNaN(fl)) {
			return elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _n0 = myrho$elm_round$Round$splitComma(
				myrho$elm_round$Round$toDecimal(
					elm$core$Basics$abs(fl)));
			var before = _n0.a;
			var after = _n0.b;
			var r = elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2(elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = elm$core$String$length(normalized);
			var roundDigitIndex = A2(elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3(elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3(elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? elm$core$String$reverse(
				A2(
					elm$core$Maybe$withDefault,
					'1',
					A2(
						elm$core$Maybe$map,
						myrho$elm_round$Round$increaseNum,
						elm$core$String$uncons(
							elm$core$String$reverse(remains))))) : remains;
			var numLen = elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					elm$core$String$repeat,
					elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				elm$core$String$length(after)) < 0) ? (A3(elm$core$String$slice, 0, numLen - s, num) + ('.' + A3(elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3(elm$core$String$padRight, s, '0', after))));
			return A2(myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var myrho$elm_round$Round$round = myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _n0 = elm$core$String$uncons(str);
			if (_n0.$ === 1) {
				return false;
			} else {
				if ('5' === _n0.a.a) {
					if (_n0.a.b === '') {
						var _n1 = _n0.a;
						return !signed;
					} else {
						var _n2 = _n0.a;
						return true;
					}
				} else {
					var _n3 = _n0.a;
					var _int = _n3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						elm$core$Char$toCode(_int));
				}
			}
		}));
var cuducos$elm_format_number$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _n1 = locale.cm;
			switch (_n1.$) {
				case 1:
					var max = _n1.a;
					return myrho$elm_round$Round$round(max);
				case 0:
					return elm$core$String$fromFloat;
				default:
					var exact = _n1.a;
					return myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _n0 = elm$core$List$tail(asList);
			if (!_n0.$) {
				var values = _n0.a;
				return A2(
					elm$core$Maybe$withDefault,
					'',
					elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			elm$core$Maybe$withDefault,
			'',
			elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var cuducos$elm_format_number$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return (elm$core$String$length(value) > 3) ? A2(
			elm$core$List$cons,
			A2(elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2(elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var cuducos$elm_format_number$Parser$parse = F2(
	function (locale, original) {
		var parts = A2(cuducos$elm_format_number$Parser$splitInParts, locale, original);
		var integers = cuducos$elm_format_number$Parser$splitThousands(
			A2(elm$core$String$filter, elm$core$Char$isDigit, parts.a));
		var decimals = A2(cuducos$elm_format_number$Parser$getDecimals, locale, parts.b);
		var partial = A5(cuducos$elm_format_number$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _n0 = cuducos$elm_format_number$Parser$classify(partial);
		switch (_n0) {
			case 2:
				return _Utils_update(
					partial,
					{aj: locale.bs, an: locale.bt});
			case 0:
				return _Utils_update(
					partial,
					{aj: locale.bE, an: locale.bF});
			default:
				return _Utils_update(
					partial,
					{aj: locale.ca, an: locale.cb});
		}
	});
var cuducos$elm_format_number$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.ac, decimals);
	});
var cuducos$elm_format_number$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = cuducos$elm_format_number$Stringfy$formatDecimals(locale);
		var integers = A2(elm$core$String$join, locale.Z, formatted.bi);
		var decimals = stringfyDecimals(formatted.cm);
		return elm$core$String$concat(
			_List_fromArray(
				[formatted.aj, integers, decimals, formatted.an]));
	});
var cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			cuducos$elm_format_number$Stringfy$stringfy,
			locale,
			A2(cuducos$elm_format_number$Parser$parse, locale, number_));
	});
var elm$html$Html$br = _VirtualDom_node('br');
var elm$html$Html$strong = _VirtualDom_node('strong');
var author$project$Page$Refund$viewGiftcardInformation = function (info) {
	if (info.$ === 1) {
		return A2(elm$html$Html$div, _List_Nil, _List_Nil);
	} else {
		var giftcard = info.a;
		var amount = A2(cuducos$elm_format_number$FormatNumber$format, author$project$Page$Refund$moneyLocale, giftcard.aW / 100);
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('giftcard-refund-result')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Det er utstedt et nytt gavekort for beløpet som var belastet kredittkort 🚀'),
							A2(elm$html$Html$br, _List_Nil, _List_Nil),
							elm$html$Html$text('Denne informasjonen er også sendt til deg på epost.')
						])),
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('giftcard-information')
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											elm$html$Html$text('Beløp: ')
										])),
									elm$html$Html$text(amount)
								])),
							A2(
							elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											elm$html$Html$text('Gavekortnr: ')
										])),
									elm$html$Html$text(giftcard.aZ)
								])),
							A2(
							elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											elm$html$Html$text('Gavekortpin: ')
										])),
									elm$html$Html$text(giftcard.bB)
								])),
							A2(
							elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											elm$html$Html$text('Utløpsdato: ')
										])),
									elm$html$Html$text(
									author$project$Page$Refund$formatIsoDate(giftcard.ct))
								]))
						]))
				]));
	}
};
var author$project$Page$Refund$viewRefundCreditcard = F2(
	function (amount, cardnumber) {
		var last4 = A2(elm$core$String$right, 4, cardnumber);
		var amountFormatted = A2(cuducos$elm_format_number$FormatNumber$format, author$project$Page$Refund$moneyLocale, (amount * (-1)) / 100);
		return A2(
			elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Refundert ' + (amountFormatted + (' til bankkort som slutter på ' + last4)))
						]))
				]));
	});
var author$project$Page$Refund$viewRefundGiftcard = F3(
	function (amount, cardnumber, pin) {
		var amountFormatted = A2(cuducos$elm_format_number$FormatNumber$format, author$project$Page$Refund$moneyLocale, (amount * (-1)) / 100);
		return A2(
			elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Refundert ' + (amountFormatted + (' til gavekortnr ' + (cardnumber + (' som har pin: ' + pin)))))
						]))
				]));
	});
var author$project$Page$Refund$viewRefund = function (refund) {
	var _n0 = refund.cV;
	if (_n0 === 1) {
		return A3(
			author$project$Page$Refund$viewRefundGiftcard,
			refund.aW,
			refund.aZ,
			A2(elm$core$Maybe$withDefault, '', refund.bB));
	} else {
		return A2(author$project$Page$Refund$viewRefundCreditcard, refund.aW, refund.aZ);
	}
};
var author$project$Page$Refund$viewRefunds = function (refunds) {
	var refunds_ = function () {
		if (elm$core$List$length(refunds) > 0) {
			var info = _List_fromArray(
				[
					A2(
					elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Du valgte å få pengene tilbake til det samme betalingsmiddelet som du brukte for å kjøpe billettene.')
						]))
				]);
			return A2(
				elm$core$List$append,
				info,
				A2(elm$core$List$map, author$project$Page$Refund$viewRefund, refunds));
		} else {
			return _List_Nil;
		}
	}();
	return refunds_;
};
var author$project$Page$Refund$viewRefundInformation = F3(
	function (action, eventStatus, refundResponse) {
		if (!action) {
			var message = function () {
				if (!eventStatus) {
					return 'Du valgte å donere billettene til artist/arrangør. De takker deg for bidraget!';
				} else {
					return 'Du valgte å beholde billettene dine.';
				}
			}();
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text(message)
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_Nil,
						author$project$Page$Refund$viewRefunds(refundResponse.cW)),
						A2(
						elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								author$project$Page$Refund$viewGiftcardInformation(refundResponse.cy)
							]))
					]));
		}
	});
var author$project$Page$Refund$viewActionProgress = F3(
	function (action, eventStatus, currentState) {
		switch (currentState.$) {
			case 0:
				return A2(
					elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Vi refunderer billettene dine')
						]));
			case 1:
				var refundResponse = currentState.a;
				return A3(author$project$Page$Refund$viewRefundInformation, action, eventStatus, refundResponse);
			default:
				var error = currentState.a;
				return A2(
					elm$html$Html$div,
					_List_Nil,
					author$project$Page$Refund$viewRefundFailure(error));
		}
	});
var author$project$Buttons$viewControl = function (content) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('control')
			]),
		_List_fromArray(
			[content]));
};
var author$project$Buttons$viewField = function (content) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('field')
			]),
		_List_fromArray(
			[content]));
};
var elm$html$Html$button = _VirtualDom_node('button');
var elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var elm$html$Html$Events$onClick = function (msg) {
	return A2(
		elm$html$Html$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var author$project$Buttons$viewButton = F3(
	function (c, t, m) {
		return author$project$Buttons$viewField(
			author$project$Buttons$viewControl(
				A2(
					elm$html$Html$button,
					_List_fromArray(
						[
							elm$html$Html$Events$onClick(m),
							elm$html$Html$Attributes$class(c)
						]),
					_List_fromArray(
						[
							elm$html$Html$text(t)
						]))));
	});
var author$project$Buttons$viewPrimaryButton = author$project$Buttons$viewButton('button is-primary');
var author$project$Page$Refund$NoOp = {$: 3};
var author$project$Page$Refund$OnRefundClick = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var author$project$Page$Refund$viewRefundOptions = F3(
	function (eventInfo, refundActions, token) {
		var _n0 = elm$core$List$length(refundActions);
		if (!_n0) {
			return A2(
				elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text('Disse billettene er allerede refundert')
					]));
		} else {
			var noActionButtonText = function () {
				var _n2 = eventInfo.c0;
				if (_n2 === 1) {
					return 'Behold billetter';
				} else {
					return 'Donér til artist/arrangør';
				}
			}();
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('refund-actions')
					]),
				elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								A2(
								author$project$Buttons$viewPrimaryButton,
								noActionButtonText,
								A3(author$project$Page$Refund$OnRefundClick, eventInfo, 0, token))
							]),
							A2(
							elm$core$List$map,
							function (action) {
								switch (action) {
									case 1:
										return A2(
											author$project$Buttons$viewPrimaryButton,
											'Refunder til betalingsmiddel',
											A3(author$project$Page$Refund$OnRefundClick, eventInfo, 1, token));
									case 2:
										return A2(
											author$project$Buttons$viewPrimaryButton,
											'Få tilsendt gavekort',
											A3(author$project$Page$Refund$OnRefundClick, eventInfo, 2, token));
									default:
										return A2(author$project$Buttons$viewPrimaryButton, 'Ukjent', author$project$Page$Refund$NoOp);
								}
							},
							refundActions)
						])));
		}
	});
var author$project$Page$Refund$viewStatus = F3(
	function (eventInfo, token, status) {
		var body = function () {
			switch (status.$) {
				case 0:
					var action = status.a;
					var message = function () {
						switch (action) {
							case 'no_action':
								return 'Du har allerede valgt å ikke refundere disse billettene.';
							case 'refund':
								return 'Disse billettene er allerede refundert til opprinnelig betalingsmiddel.';
							case 'giftcard':
								return 'Billettene er refundert til et nytt gavekort.';
							default:
								return 'Disse billettene er allerede refundert.';
						}
					}();
					return _List_fromArray(
						[
							A2(
							elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									elm$html$Html$text(message)
								]))
						]);
				case 1:
					var actions = status.a;
					var greeting = function () {
						var _n2 = eventInfo.c0;
						if (_n2 === 1) {
							return _List_fromArray(
								[
									elm$html$Html$text('Dette arrangementet er blitt flyttet.'),
									A2(elm$html$Html$br, _List_Nil, _List_Nil),
									A2(elm$html$Html$br, _List_Nil, _List_Nil),
									elm$html$Html$text('Du kan velge mellom å få pengene tilbake, eller beholde dine billetter.')
								]);
						} else {
							return _List_fromArray(
								[
									elm$html$Html$text('Dette arrangementet er avlyst.'),
									A2(elm$html$Html$br, _List_Nil, _List_Nil),
									A2(elm$html$Html$br, _List_Nil, _List_Nil),
									elm$html$Html$text('Du kan velge mellom å få pengene tilbake, eller beholde dine billetter.')
								]);
						}
					}();
					return _List_fromArray(
						[
							A2(elm$html$Html$p, _List_Nil, greeting),
							A3(author$project$Page$Refund$viewRefundOptions, eventInfo, actions, token)
						]);
				case 2:
					var action = status.a;
					var currentState = status.b;
					return _List_fromArray(
						[
							A3(author$project$Page$Refund$viewActionProgress, action, eventInfo.c0, currentState)
						]);
				default:
					return _List_fromArray(
						[
							A2(
							elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									elm$html$Html$text('Fristen for refunderinger på dette arrangementet er utløpt. Kontakt arrangøren dersom du har spørsmål.')
								]))
						]);
			}
		}();
		return A2(elm$html$Html$div, _List_Nil, body);
	});
var elm$html$Html$h1 = _VirtualDom_node('h1');
var author$project$Page$Refund$viewContent = function (model) {
	var _n0 = model.c0;
	switch (_n0.$) {
		case 0:
			return A2(
				elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text('Henter arrangementsinformasjon')
					]));
		case 1:
			var error = _n0.a;
			return A2(
				elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text('ukjent feil ved sjekk av token')
					]));
		default:
			var token = _n0.a;
			var eventInfo = _n0.b;
			var status = _n0.c;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						author$project$Page$Refund$viewEventInfo(eventInfo),
						A2(
						elm$html$Html$h1,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('title')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('Refundering av billetter')
							])),
						A3(author$project$Page$Refund$viewStatus, eventInfo, token, status)
					]));
	}
};
var author$project$Page$Refund$view = function (model) {
	return author$project$Layout$viewSection(
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('columns')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('column is-8-desktop is-12-mobile is-10-tablet is-offset-2-desktop is-offset-1-tablet')
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$div,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('tile is-ancestor')
									]),
								_List_fromArray(
									[
										A2(
										elm$html$Html$div,
										_List_fromArray(
											[
												elm$html$Html$Attributes$class('tile is-parent')
											]),
										_List_fromArray(
											[
												A2(
												elm$html$Html$div,
												_List_fromArray(
													[
														elm$html$Html$Attributes$class('tile is-child box')
													]),
												_List_fromArray(
													[
														author$project$Page$Refund$viewContent(model)
													]))
											]))
									]))
							]))
					]))
			]));
};
var elm$html$Html$input = _VirtualDom_node('input');
var elm$html$Html$Attributes$placeholder = elm$html$Html$Attributes$stringProperty('placeholder');
var elm$html$Html$Attributes$type_ = elm$html$Html$Attributes$stringProperty('type');
var elm$html$Html$Attributes$value = elm$html$Html$Attributes$stringProperty('value');
var elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3(elm$core$List$foldr, elm$json$Json$Decode$field, decoder, fields);
	});
var elm$html$Html$Events$targetValue = A2(
	elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	elm$json$Json$Decode$string);
var elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			elm$json$Json$Decode$map,
			elm$html$Html$Events$alwaysStop,
			A2(elm$json$Json$Decode$map, tagger, elm$html$Html$Events$targetValue)));
};
var author$project$Inputs$textInput = F3(
	function (p, v, msg) {
		return A2(
			elm$html$Html$input,
			_List_fromArray(
				[
					elm$html$Html$Attributes$type_('text'),
					elm$html$Html$Events$onInput(msg),
					elm$html$Html$Attributes$class('input'),
					elm$html$Html$Attributes$placeholder(p),
					elm$html$Html$Attributes$value(v),
					elm$html$Html$Events$onInput(msg)
				]),
			_List_Nil);
	});
var author$project$Inputs$withoutLabel = function (config) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('field')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('control')
					]),
				_List_fromArray(
					[
						A3(author$project$Inputs$textInput, config.bC, config.D, config.bv)
					]))
			]));
};
var elm$html$Html$label = _VirtualDom_node('label');
var author$project$Inputs$withLabel = function (config) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('field is-horizontal')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('field-label is-normal')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$label,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('label')
							]),
						_List_fromArray(
							[
								elm$html$Html$text(
								A2(elm$core$Maybe$withDefault, '', config.T))
							]))
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('field-body')
					]),
				_List_fromArray(
					[
						author$project$Inputs$withoutLabel(config)
					]))
			]));
};
var author$project$Inputs$viewTextField = function (config) {
	var _n0 = config.T;
	if (!_n0.$) {
		return author$project$Inputs$withLabel(config);
	} else {
		return author$project$Inputs$withoutLabel(config);
	}
};
var author$project$Page$Remittance$InputChanged = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Page$Remittance$NameInput = 0;
var author$project$Page$Remittance$OnClickSubmit = {$: 4};
var author$project$Page$Remittance$viewBox = function (children) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('columns')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('column is-6-desktop is-12-mobile is-8-tablet is-offset-3-desktop is-offset-2-tablet')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('tile is-ancestor')
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$div,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('tile is-parent')
									]),
								_List_fromArray(
									[
										A2(
										elm$html$Html$div,
										_List_fromArray(
											[
												elm$html$Html$Attributes$class('tile is-child box')
											]),
										children)
									]))
							]))
					]))
			]));
};
var abadi199$elm_input_extra$MaskedInput$Text$defaultOptions = F2(
	function (onInput, toMsg) {
		return {af: elm$core$Maybe$Nothing, aD: '#', bv: onInput, cR: '', ap: toMsg};
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$append = F3(
	function (tokens, input, formatted) {
		append:
		while (true) {
			var maybeToken = elm$core$List$head(tokens);
			var appendInput = A2(
				elm$core$Maybe$withDefault,
				formatted,
				A2(
					elm$core$Maybe$map,
					A2(
						abadi199$elm_input_extra$MaskedInput$Pattern$append,
						A2(
							elm$core$Maybe$withDefault,
							_List_Nil,
							elm$core$List$tail(tokens)),
						A2(
							elm$core$Maybe$withDefault,
							_List_Nil,
							elm$core$List$tail(input))),
					A2(
						elm$core$Maybe$map,
						function (_char) {
							return _Utils_ap(
								formatted,
								elm$core$String$fromChar(_char));
						},
						elm$core$List$head(input))));
			if (maybeToken.$ === 1) {
				return formatted;
			} else {
				var token = maybeToken.a;
				if (!token.$) {
					return appendInput;
				} else {
					var _char = token.a;
					var $temp$tokens = A2(
						elm$core$Maybe$withDefault,
						_List_Nil,
						elm$core$List$tail(tokens)),
						$temp$input = input,
						$temp$formatted = _Utils_ap(
						formatted,
						elm$core$String$fromChar(_char));
					tokens = $temp$tokens;
					input = $temp$input;
					formatted = $temp$formatted;
					continue append;
				}
			}
		}
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$format = F2(
	function (tokens, input) {
		return elm$core$String$isEmpty(input) ? input : A3(
			abadi199$elm_input_extra$MaskedInput$Pattern$append,
			tokens,
			elm$core$String$toList(input),
			'');
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$Input = {$: 0};
var abadi199$elm_input_extra$MaskedInput$Pattern$Other = function (a) {
	return {$: 1, a: a};
};
var abadi199$elm_input_extra$MaskedInput$Pattern$tokenize = F2(
	function (inputChar, pattern) {
		return _Utils_eq(pattern, inputChar) ? abadi199$elm_input_extra$MaskedInput$Pattern$Input : abadi199$elm_input_extra$MaskedInput$Pattern$Other(pattern);
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$parse = F2(
	function (inputChar, pattern) {
		return A2(
			elm$core$List$map,
			abadi199$elm_input_extra$MaskedInput$Pattern$tokenize(inputChar),
			elm$core$String$toList(pattern));
	});
var abadi199$elm_input_extra$Input$Decoder$Event = F5(
	function (keyCode, ctrlKey, altKey, metaKey, shiftKey) {
		return {cd: altKey, cj: ctrlKey, S: keyCode, cI: metaKey, c$: shiftKey};
	});
var elm$json$Json$Decode$bool = _Json_decodeBool;
var elm$json$Json$Decode$map5 = _Json_map5;
var abadi199$elm_input_extra$Input$Decoder$eventDecoder = A6(
	elm$json$Json$Decode$map5,
	abadi199$elm_input_extra$Input$Decoder$Event,
	A2(elm$json$Json$Decode$field, 'keyCode', elm$json$Json$Decode$int),
	A2(elm$json$Json$Decode$field, 'ctrlKey', elm$json$Json$Decode$bool),
	A2(elm$json$Json$Decode$field, 'altKey', elm$json$Json$Decode$bool),
	A2(elm$json$Json$Decode$field, 'metaKey', elm$json$Json$Decode$bool),
	A2(elm$json$Json$Decode$field, 'shiftKey', elm$json$Json$Decode$bool));
var elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var abadi199$elm_input_extra$MaskedInput$Text$onKeyDown = F3(
	function (currentFormattedValue, tokens, toMsg) {
		var filterKey = function (event) {
			return elm$json$Json$Decode$succeed(event.S);
		};
		var decoder = A2(
			elm$json$Json$Decode$map,
			function (keyCode) {
				return {
					bm: toMsg(
						elm$core$Maybe$Just(keyCode)),
					bG: false,
					b_: false
				};
			},
			A2(elm$json$Json$Decode$andThen, filterKey, abadi199$elm_input_extra$Input$Decoder$eventDecoder));
		return A2(elm$html$Html$Events$custom, 'keydown', decoder);
	});
var abadi199$elm_input_extra$Input$KeyCode$alt = 18;
var abadi199$elm_input_extra$Input$KeyCode$backspace = 8;
var abadi199$elm_input_extra$Input$KeyCode$ctrl = 17;
var abadi199$elm_input_extra$Input$KeyCode$delete = 46;
var abadi199$elm_input_extra$Input$KeyCode$downArrow = 40;
var abadi199$elm_input_extra$Input$KeyCode$enter = 13;
var abadi199$elm_input_extra$Input$KeyCode$leftArrow = 37;
var abadi199$elm_input_extra$Input$KeyCode$rightArrow = 39;
var abadi199$elm_input_extra$Input$KeyCode$shift = 16;
var abadi199$elm_input_extra$Input$KeyCode$tab = 9;
var abadi199$elm_input_extra$Input$KeyCode$upArrow = 38;
var abadi199$elm_input_extra$Input$KeyCode$allowedKeyCodes = _List_fromArray(
	[abadi199$elm_input_extra$Input$KeyCode$leftArrow, abadi199$elm_input_extra$Input$KeyCode$upArrow, abadi199$elm_input_extra$Input$KeyCode$rightArrow, abadi199$elm_input_extra$Input$KeyCode$downArrow, abadi199$elm_input_extra$Input$KeyCode$backspace, abadi199$elm_input_extra$Input$KeyCode$ctrl, abadi199$elm_input_extra$Input$KeyCode$alt, abadi199$elm_input_extra$Input$KeyCode$delete, abadi199$elm_input_extra$Input$KeyCode$tab, abadi199$elm_input_extra$Input$KeyCode$enter, abadi199$elm_input_extra$Input$KeyCode$shift]);
var abadi199$elm_input_extra$MaskedInput$Text$onKeyPress = F3(
	function (currentFormattedValue, tokens, toMsg) {
		var filterKey = function (event) {
			return (event.cj || event.cd) ? elm$json$Json$Decode$fail('modifier key is pressed') : (A2(
				elm$core$List$any,
				elm$core$Basics$eq(event.S),
				abadi199$elm_input_extra$Input$KeyCode$allowedKeyCodes) ? elm$json$Json$Decode$fail('not arrow') : ((_Utils_cmp(
				elm$core$String$length(currentFormattedValue),
				elm$core$List$length(tokens)) < 0) ? elm$json$Json$Decode$fail('accepting more input') : elm$json$Json$Decode$succeed(event.S)));
		};
		var decoder = A2(
			elm$json$Json$Decode$map,
			function (keyCode) {
				return {
					bm: toMsg(
						elm$core$Maybe$Just(keyCode)),
					bG: false,
					b_: false
				};
			},
			A2(elm$json$Json$Decode$andThen, filterKey, abadi199$elm_input_extra$Input$Decoder$eventDecoder));
		return A2(elm$html$Html$Events$custom, 'keypress', decoder);
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$Backspace = 0;
var abadi199$elm_input_extra$MaskedInput$Pattern$Delete = 1;
var abadi199$elm_input_extra$MaskedInput$Pattern$OtherUpdate = 2;
var abadi199$elm_input_extra$Diff$Added = function (a) {
	return {$: 2, a: a};
};
var abadi199$elm_input_extra$Diff$Removed = function (a) {
	return {$: 3, a: a};
};
var abadi199$elm_input_extra$Diff$bestScore = F2(
	function (ma, mb) {
		var _n0 = _Utils_Tuple2(ma, mb);
		if (_n0.b.$ === 1) {
			var m = _n0.a;
			var _n1 = _n0.b;
			return m;
		} else {
			if (_n0.a.$ === 1) {
				var _n2 = _n0.a;
				var m = _n0.b;
				return m;
			} else {
				var _n3 = _n0.a.a;
				var sa = _n3.a;
				var ca = _n3.b;
				var _n4 = _n0.b.a;
				var sb = _n4.a;
				var cb = _n4.b;
				return (_Utils_cmp(sb, sa) > 0) ? elm$core$Maybe$Just(
					_Utils_Tuple2(sb, cb)) : elm$core$Maybe$Just(
					_Utils_Tuple2(sa, ca));
			}
		}
	});
var abadi199$elm_input_extra$Diff$Changed = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var abadi199$elm_input_extra$Diff$NoChange = function (a) {
	return {$: 0, a: a};
};
var abadi199$elm_input_extra$Diff$UseA = 1;
var abadi199$elm_input_extra$Diff$UseB = 2;
var abadi199$elm_input_extra$Diff$UseBoth = 0;
var abadi199$elm_input_extra$Diff$choices = F2(
	function (a, b) {
		return _Utils_eq(a, b) ? _List_fromArray(
			[
				_Utils_Tuple3(
				1,
				0,
				abadi199$elm_input_extra$Diff$Removed(a)),
				_Utils_Tuple3(
				2,
				0,
				abadi199$elm_input_extra$Diff$Added(b)),
				_Utils_Tuple3(
				0,
				1,
				abadi199$elm_input_extra$Diff$NoChange(a))
			]) : _List_fromArray(
			[
				_Utils_Tuple3(
				1,
				0,
				abadi199$elm_input_extra$Diff$Removed(a)),
				_Utils_Tuple3(
				2,
				0,
				abadi199$elm_input_extra$Diff$Added(b)),
				_Utils_Tuple3(
				0,
				0,
				A2(abadi199$elm_input_extra$Diff$Changed, a, b))
			]);
	});
var abadi199$elm_input_extra$Diff$orNoChange = function (c) {
	if (!c.$) {
		var a = c.a;
		return a;
	} else {
		return _Utils_Tuple2(
			0,
			_List_fromArray(
				[
					abadi199$elm_input_extra$Diff$NoChange('')
				]));
	}
};
var abadi199$elm_input_extra$Diff$score = F3(
	function (add, c, _n0) {
		var s = _n0.a;
		var cs = _n0.b;
		return _Utils_Tuple2(
			s + add,
			A2(elm$core$List$cons, c, cs));
	});
var abadi199$elm_input_extra$Diff$scores = F4(
	function (tl, t, l, _n0) {
		var from = _n0.a;
		var add = _n0.b;
		var c = _n0.c;
		return A2(
			elm$core$Maybe$map,
			A2(abadi199$elm_input_extra$Diff$score, add, c),
			function () {
				switch (from) {
					case 1:
						return t;
					case 2:
						return l;
					default:
						return tl;
				}
			}());
	});
var abadi199$elm_input_extra$Diff$best = F5(
	function (tl, t, l, a, b) {
		return abadi199$elm_input_extra$Diff$orNoChange(
			A3(
				elm$core$List$foldl,
				abadi199$elm_input_extra$Diff$bestScore,
				elm$core$Maybe$Nothing,
				A2(
					elm$core$List$map,
					A3(abadi199$elm_input_extra$Diff$scores, tl, t, l),
					A2(abadi199$elm_input_extra$Diff$choices, a, b))));
	});
var abadi199$elm_input_extra$Diff$val = F3(
	function (row, col, s) {
		return A2(
			elm$core$Dict$get,
			_Utils_Tuple2(row, col),
			s);
	});
var abadi199$elm_input_extra$Diff$calcCell = F3(
	function (_n0, _n1, s) {
		var row = _n0.a;
		var a = _n0.b;
		var col = _n1.a;
		var b = _n1.b;
		return A3(
			elm$core$Dict$insert,
			_Utils_Tuple2(row, col),
			A5(
				abadi199$elm_input_extra$Diff$best,
				A3(abadi199$elm_input_extra$Diff$val, row - 1, col - 1, s),
				A3(abadi199$elm_input_extra$Diff$val, row - 1, col, s),
				A3(abadi199$elm_input_extra$Diff$val, row, col - 1, s),
				a,
				b),
			s);
	});
var abadi199$elm_input_extra$Diff$calcRow = F3(
	function (bs, _n0, d) {
		var row = _n0.a;
		var a = _n0.b;
		return A3(
			elm$core$List$foldl,
			abadi199$elm_input_extra$Diff$calcCell(
				_Utils_Tuple2(row, a)),
			d,
			A2(elm$core$List$indexedMap, elm$core$Tuple$pair, bs));
	});
var elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
	});
var abadi199$elm_input_extra$Diff$initialGrid = F2(
	function (az, bs) {
		return function (d) {
			return A3(
				elm$core$List$foldl,
				function (a) {
					return A2(
						abadi199$elm_input_extra$Diff$calcCell,
						a,
						_Utils_Tuple2(-1, ''));
				},
				d,
				A2(elm$core$List$indexedMap, elm$core$Tuple$pair, az));
		}(
			A3(
				abadi199$elm_input_extra$Diff$calcRow,
				bs,
				_Utils_Tuple2(-1, ''),
				A2(
					elm$core$Dict$singleton,
					_Utils_Tuple2(-1, -1),
					_Utils_Tuple2(0, _List_Nil))));
	});
var abadi199$elm_input_extra$Diff$calcGrid = F2(
	function (az, bs) {
		return A3(
			elm$core$List$foldl,
			abadi199$elm_input_extra$Diff$calcRow(bs),
			A2(abadi199$elm_input_extra$Diff$initialGrid, az, bs),
			A2(elm$core$List$indexedMap, elm$core$Tuple$pair, az));
	});
var abadi199$elm_input_extra$Diff$mergeAll = F2(
	function (next, list) {
		var _n0 = _Utils_Tuple2(next, list);
		_n0$8:
		while (true) {
			if (_n0.b.b) {
				switch (_n0.a.$) {
					case 2:
						switch (_n0.b.a.$) {
							case 2:
								var a = _n0.a.a;
								var _n1 = _n0.b;
								var b = _n1.a.a;
								var rest = _n1.b;
								return A2(
									elm$core$List$cons,
									abadi199$elm_input_extra$Diff$Added(
										_Utils_ap(a, b)),
									rest);
							case 3:
								var a = _n0.a.a;
								var _n2 = _n0.b;
								var b = _n2.a.a;
								var rest = _n2.b;
								return A2(
									elm$core$List$cons,
									A2(abadi199$elm_input_extra$Diff$Changed, b, a),
									rest);
							case 1:
								var a = _n0.a.a;
								var _n3 = _n0.b;
								var _n4 = _n3.a;
								var b1 = _n4.a;
								var b2 = _n4.b;
								var rest = _n3.b;
								return A2(
									elm$core$List$cons,
									A2(
										abadi199$elm_input_extra$Diff$Changed,
										b1,
										_Utils_ap(a, b2)),
									rest);
							default:
								break _n0$8;
						}
					case 3:
						switch (_n0.b.a.$) {
							case 3:
								var a = _n0.a.a;
								var _n5 = _n0.b;
								var b = _n5.a.a;
								var rest = _n5.b;
								return A2(
									elm$core$List$cons,
									abadi199$elm_input_extra$Diff$Removed(
										_Utils_ap(a, b)),
									rest);
							case 2:
								var a = _n0.a.a;
								var _n6 = _n0.b;
								var b = _n6.a.a;
								var rest = _n6.b;
								return A2(
									elm$core$List$cons,
									A2(abadi199$elm_input_extra$Diff$Changed, a, b),
									rest);
							case 1:
								var a = _n0.a.a;
								var _n7 = _n0.b;
								var _n8 = _n7.a;
								var b1 = _n8.a;
								var b2 = _n8.b;
								var rest = _n7.b;
								return A2(
									elm$core$List$cons,
									A2(
										abadi199$elm_input_extra$Diff$Changed,
										_Utils_ap(a, b1),
										b2),
									rest);
							default:
								break _n0$8;
						}
					case 0:
						if (!_n0.b.a.$) {
							var a = _n0.a.a;
							var _n9 = _n0.b;
							var b = _n9.a.a;
							var rest = _n9.b;
							return A2(
								elm$core$List$cons,
								abadi199$elm_input_extra$Diff$NoChange(
									_Utils_ap(a, b)),
								rest);
						} else {
							break _n0$8;
						}
					default:
						if (_n0.b.a.$ === 1) {
							var _n10 = _n0.a;
							var a1 = _n10.a;
							var a2 = _n10.b;
							var _n11 = _n0.b;
							var _n12 = _n11.a;
							var b1 = _n12.a;
							var b2 = _n12.b;
							var rest = _n11.b;
							return A2(
								elm$core$List$cons,
								A2(
									abadi199$elm_input_extra$Diff$Changed,
									_Utils_ap(a1, b1),
									_Utils_ap(a2, b2)),
								rest);
						} else {
							break _n0$8;
						}
				}
			} else {
				break _n0$8;
			}
		}
		return A2(elm$core$List$cons, next, list);
	});
var abadi199$elm_input_extra$Diff$diff = F3(
	function (tokenize, a, b) {
		var bs = tokenize(b);
		var az = tokenize(a);
		return _Utils_eq(az, _List_Nil) ? A3(
			elm$core$List$foldr,
			abadi199$elm_input_extra$Diff$mergeAll,
			_List_Nil,
			A2(elm$core$List$map, abadi199$elm_input_extra$Diff$Added, bs)) : (_Utils_eq(bs, _List_Nil) ? A3(
			elm$core$List$foldr,
			abadi199$elm_input_extra$Diff$mergeAll,
			_List_Nil,
			A2(elm$core$List$map, abadi199$elm_input_extra$Diff$Removed, az)) : A3(
			elm$core$List$foldl,
			abadi199$elm_input_extra$Diff$mergeAll,
			_List_Nil,
			A2(
				elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					elm$core$Maybe$map,
					function (_n0) {
						var changes = _n0.b;
						return changes;
					},
					A2(
						elm$core$Dict$get,
						_Utils_Tuple2(
							(-1) + elm$core$List$length(az),
							(-1) + elm$core$List$length(bs)),
						A2(abadi199$elm_input_extra$Diff$calcGrid, az, bs))))));
	});
var abadi199$elm_input_extra$Diff$diffChars = abadi199$elm_input_extra$Diff$diff(
	elm$core$String$split(''));
var elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? elm$core$Maybe$Nothing : elm$core$List$head(
			A2(elm$core$List$drop, idx, xs));
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$isAdd = function (change) {
	if (change.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var abadi199$elm_input_extra$MaskedInput$Pattern$splitChanges = function (changes) {
	var splitString = F2(
		function (change, str) {
			return A2(
				elm$core$List$map,
				change,
				A2(
					elm$core$List$map,
					elm$core$String$fromChar,
					elm$core$String$toList(str)));
		});
	var split = function (change) {
		switch (change.$) {
			case 0:
				var str = change.a;
				return A2(splitString, abadi199$elm_input_extra$Diff$NoChange, str);
			case 2:
				var str = change.a;
				return A2(splitString, abadi199$elm_input_extra$Diff$Added, str);
			case 3:
				var str = change.a;
				return A2(splitString, abadi199$elm_input_extra$Diff$Removed, str);
			default:
				var str = change.b;
				return A2(
					splitString,
					abadi199$elm_input_extra$Diff$Changed(''),
					str);
		}
	};
	return elm$core$List$concat(
		A2(elm$core$List$map, split, changes));
};
var abadi199$elm_input_extra$MaskedInput$Pattern$changesPairWithToken = F3(
	function (tokens, previous, current) {
		var splittedChanges = abadi199$elm_input_extra$MaskedInput$Pattern$splitChanges(
			A2(abadi199$elm_input_extra$Diff$diffChars, previous, current));
		var totalChanges = elm$core$List$length(splittedChanges);
		var getToken = F2(
			function (index, change) {
				if (change.$ === 2) {
					return elm$core$Maybe$Nothing;
				} else {
					var tokenIndex = (_Utils_cmp(
						elm$core$List$length(tokens),
						elm$core$String$length(previous)) < 0) ? ((elm$core$List$length(tokens) - 1) - index) : ((elm$core$String$length(previous) - 1) - index);
					return A2(abadi199$elm_input_extra$MaskedInput$Pattern$getAt, tokenIndex, tokens);
				}
			});
		var toPair = F2(
			function (change, results) {
				var index = elm$core$List$length(
					A2(
						elm$core$List$filter,
						function (_n1) {
							var change_ = _n1.b;
							return !abadi199$elm_input_extra$MaskedInput$Pattern$isAdd(change_);
						},
						results));
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(
						A2(getToken, index, change),
						change),
					results);
			});
		var checkForLength = function (changes) {
			return (_Utils_cmp(
				elm$core$List$length(changes),
				elm$core$List$length(tokens)) > 0) ? A2(
				elm$core$List$filter,
				function (_n0) {
					var a = _n0.a;
					var change = _n0.b;
					return !abadi199$elm_input_extra$MaskedInput$Pattern$isAdd(change);
				},
				changes) : changes;
		};
		return A2(
			elm$core$List$take,
			elm$core$List$length(tokens),
			checkForLength(
				A3(elm$core$List$foldr, toPair, _List_Nil, splittedChanges)));
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$foldPairs = F2(
	function (adjustment, pairs) {
		var concat = F3(
			function (isLeft, s, str) {
				return isLeft ? _Utils_ap(str, s) : _Utils_ap(s, str);
			});
		var fold = F3(
			function (isLeft, pair, str) {
				if (!pair.a.$) {
					if (pair.a.a.$ === 1) {
						switch (pair.b.$) {
							case 3:
								var s = pair.b.a;
								return isLeft ? A2(elm$core$String$dropRight, 1, str) : A2(elm$core$String$dropLeft, 1, str);
							case 2:
								return str;
							case 1:
								var _n2 = pair.b;
								return str;
							default:
								return str;
						}
					} else {
						switch (pair.b.$) {
							case 3:
								var _n3 = pair.a.a;
								var s = pair.b.a;
								return str;
							case 2:
								var _n4 = pair.a.a;
								var s = pair.b.a;
								return A3(concat, isLeft, s, str);
							case 1:
								var _n5 = pair.a.a;
								var _n6 = pair.b;
								var s = _n6.b;
								return A3(concat, isLeft, s, str);
							default:
								var _n7 = pair.a.a;
								var s = pair.b.a;
								return A3(concat, isLeft, s, str);
						}
					}
				} else {
					switch (pair.b.$) {
						case 3:
							var _n8 = pair.a;
							var s = pair.b.a;
							return str;
						case 2:
							var _n9 = pair.a;
							var s = pair.b.a;
							return A3(concat, isLeft, s, str);
						case 1:
							var _n10 = pair.a;
							var _n11 = pair.b;
							var s = _n11.b;
							return A3(concat, isLeft, s, str);
						default:
							var _n12 = pair.a;
							var s = pair.b.a;
							return A3(concat, isLeft, s, str);
					}
				}
			});
		var left = A3(
			elm$core$List$foldl,
			fold(true),
			'',
			pairs);
		var right = A3(
			elm$core$List$foldr,
			fold(false),
			'',
			pairs);
		if (!adjustment) {
			return left;
		} else {
			return right;
		}
	});
var abadi199$elm_input_extra$MaskedInput$Pattern$adjust = F4(
	function (tokens, adjustment, previous, current) {
		return A2(
			abadi199$elm_input_extra$MaskedInput$Pattern$foldPairs,
			adjustment,
			A3(abadi199$elm_input_extra$MaskedInput$Pattern$changesPairWithToken, tokens, previous, current));
	});
var abadi199$elm_input_extra$MaskedInput$Text$processInput = F5(
	function (options, tokens, state, oldValue, value) {
		var adjustment = function () {
			_n0$2:
			while (true) {
				if (!state.$) {
					switch (state.a) {
						case 8:
							return 0;
						case 46:
							return 1;
						default:
							break _n0$2;
					}
				} else {
					break _n0$2;
				}
			}
			return 2;
		}();
		return options.bv(
			A4(abadi199$elm_input_extra$MaskedInput$Pattern$adjust, tokens, adjustment, oldValue, value));
	});
var elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		elm$html$Html$Events$on,
		'blur',
		elm$json$Json$Decode$succeed(msg));
};
var elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		elm$html$Html$Events$on,
		'focus',
		elm$json$Json$Decode$succeed(msg));
};
var abadi199$elm_input_extra$MaskedInput$Text$input = F4(
	function (options, attributes, state, currentValue) {
		var tokens = A2(abadi199$elm_input_extra$MaskedInput$Pattern$parse, options.aD, options.cR);
		var onFocusAttribute = A2(
			elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				elm$core$Maybe$map,
				function (a) {
					return A2(elm$core$List$cons, a, _List_Nil);
				},
				A2(
					elm$core$Maybe$map,
					elm$html$Html$Events$onFocus,
					A2(
						elm$core$Maybe$map,
						function (f) {
							return f(true);
						},
						options.af))));
		var onBlurAttribute = A2(
			elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				elm$core$Maybe$map,
				function (a) {
					return A2(elm$core$List$cons, a, _List_Nil);
				},
				A2(
					elm$core$Maybe$map,
					elm$html$Html$Events$onBlur,
					A2(
						elm$core$Maybe$map,
						function (f) {
							return f(false);
						},
						options.af))));
		var currentFormattedValue = A2(abadi199$elm_input_extra$MaskedInput$Pattern$format, tokens, currentValue);
		return A2(
			elm$html$Html$input,
			A2(
				elm$core$List$append,
				onBlurAttribute,
				A2(
					elm$core$List$append,
					onFocusAttribute,
					A2(
						elm$core$List$append,
						attributes,
						_List_fromArray(
							[
								elm$html$Html$Attributes$value(currentFormattedValue),
								elm$html$Html$Events$onInput(
								A4(abadi199$elm_input_extra$MaskedInput$Text$processInput, options, tokens, state, currentFormattedValue)),
								A3(abadi199$elm_input_extra$MaskedInput$Text$onKeyDown, currentFormattedValue, tokens, options.ap),
								A3(abadi199$elm_input_extra$MaskedInput$Text$onKeyPress, currentFormattedValue, tokens, options.ap),
								elm$html$Html$Attributes$type_('text')
							])))),
			_List_Nil);
	});
var author$project$Page$Remittance$AccountInput = 1;
var author$project$Page$Remittance$InputStateChanged = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Remittance$viewMaskedInput = F2(
	function (state, value) {
		var defaultOptions = A2(
			abadi199$elm_input_extra$MaskedInput$Text$defaultOptions,
			author$project$Page$Remittance$InputChanged(1),
			author$project$Page$Remittance$InputStateChanged);
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A4(
							abadi199$elm_input_extra$MaskedInput$Text$input,
							_Utils_update(
								defaultOptions,
								{cR: '####.##.#####'}),
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('input'),
									elm$html$Html$Attributes$placeholder('Kontonummer')
								]),
							state,
							value)
						]))
				]));
	});
var author$project$Page$Remittance$formatPosix = function (posix) {
	return A3(
		ryannhg$date_format$DateFormat$format,
		_List_fromArray(
			[
				ryannhg$date_format$DateFormat$dayOfMonthFixed,
				ryannhg$date_format$DateFormat$text('.'),
				ryannhg$date_format$DateFormat$monthFixed,
				ryannhg$date_format$DateFormat$text('.'),
				ryannhg$date_format$DateFormat$yearNumber,
				ryannhg$date_format$DateFormat$text(' kl '),
				ryannhg$date_format$DateFormat$hourMilitaryFromOneFixed,
				ryannhg$date_format$DateFormat$text(':'),
				ryannhg$date_format$DateFormat$minuteFixed
			]),
		elm$time$Time$utc,
		posix);
};
var author$project$Page$Remittance$viewRefundEntityInfo = F2(
	function (entity, partner) {
		if (!entity.$) {
			var event = entity.a;
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('event-info')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text(event.c5)
							])),
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('event-info-sub')
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										elm$html$Html$text(
										author$project$Page$Remittance$formatPosix(event.aX))
									])),
								A2(
								elm$html$Html$p,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('partner-name has-text-right')
									]),
								_List_fromArray(
									[
										elm$html$Html$text(partner.cK)
									]))
							]))
					]));
		} else {
			var subscription = entity.a;
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('event-info')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text(subscription.c5)
							])),
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('event-info-sub')
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$p,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('partner-name has-text-right')
									]),
								_List_fromArray(
									[
										elm$html$Html$text(partner.cK)
									]))
							]))
					]));
		}
	});
var author$project$Page$Remittance$viewContent = function (model) {
	var validationMessage = function () {
		var _n6 = model.r;
		if (_n6 === 2) {
			return _List_fromArray(
				[
					A2(
					elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Kontonummeret du har skrevet inn er ikke et gyldig kontonummer.')
						]))
				]);
		} else {
			return _List_Nil;
		}
	}();
	var _n0 = model.cK;
	var name = _n0;
	var _n1 = model.v;
	var state = _n1.a;
	var account = _n1.b;
	var _n2 = model.N;
	switch (_n2.$) {
		case 1:
			var refundEntity = _n2.a;
			var buyer = _n2.b;
			var partner = _n2.c;
			var purchase = _n2.d;
			var refund = _n2.e;
			var _n3 = refund.c0;
			switch (_n3) {
				case 0:
					var _n4 = model.r;
					switch (_n4) {
						case 3:
							return author$project$Layout$viewSection(
								_List_fromArray(
									[
										author$project$Page$Remittance$viewBox(
										_List_fromArray(
											[
												A2(
												elm$html$Html$div,
												_List_Nil,
												_List_fromArray(
													[
														elm$html$Html$text('Registrerer din info')
													]))
											]))
									]));
						case 4:
							return author$project$Layout$viewSection(
								_List_fromArray(
									[
										author$project$Page$Remittance$viewBox(
										_List_fromArray(
											[
												A2(
												elm$html$Html$div,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																elm$html$Html$text('Takk!')
															])),
														A2(
														elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																elm$html$Html$text('Din informasjon er nå registrert, og beløpet vil bli overført i løpet av 1-2 virkedager.')
															]))
													]))
											]))
									]));
						case 5:
							return author$project$Layout$viewSection(
								_List_fromArray(
									[
										author$project$Page$Remittance$viewBox(
										_List_fromArray(
											[
												A2(
												elm$html$Html$div,
												_List_Nil,
												_List_fromArray(
													[
														elm$html$Html$text('Det skjedde en feil, prøv igjen senere')
													]))
											]))
									]));
						default:
							var aboutText = function () {
								if (!refundEntity.$) {
									return 'Refunderinger gjelder: ' + (elm$core$String$fromInt(purchase.au) + (' billett(er) for ' + (elm$core$String$fromFloat(purchase.aW / 100) + ' kroner')));
								} else {
									return 'Refunderinger gjelder: ' + (elm$core$String$fromInt(purchase.au) + (' abonnement(er) for ' + (elm$core$String$fromFloat(purchase.aW / 100) + ' kroner')));
								}
							}();
							return author$project$Layout$viewSection(
								_List_fromArray(
									[
										author$project$Page$Remittance$viewBox(
										_Utils_ap(
											_List_fromArray(
												[
													A2(author$project$Page$Remittance$viewRefundEntityInfo, refundEntity, partner),
													A2(
													elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	elm$html$Html$text('\n                                                        For at vi skal kunne gjennomføre refusjon av ditt billettkjøp trenger\n                                                        vi litt mer informasjon. Årsaken til dette er at det er gått mer enn 12 måneder siden ditt\n                                                        kjøp ble gjort og vi har dermed ikke mulighet til å tilbakeføre til kortet du benyttet ved kjøp.\n                                                        ')
																])),
															A2(
															elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	elm$html$Html$strong,
																	_List_Nil,
																	_List_fromArray(
																		[
																			elm$html$Html$text('Vi trenger ditt navn og et kontonummer vi kan overføre beløpet til.')
																		]))
																])),
															A2(
															elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	elm$html$Html$text('Dersom du ikke ønsker å oppgi ditt kontonummer her kan du ta kontakt med ' + (partner.cK + ' og avtale annullering av ditt billettkjøp med dem.'))
																])),
															A2(
															elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	elm$html$Html$text('Vi vil slette kontoinformasjon når refusjonen er gjennomført.')
																])),
															A2(
															elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	elm$html$Html$text(aboutText)
																])),
															A2(elm$html$Html$br, _List_Nil, _List_Nil)
														])),
													author$project$Inputs$viewTextField(
													{
														T: elm$core$Maybe$Nothing,
														bv: author$project$Page$Remittance$InputChanged(0),
														bC: 'Navn',
														D: name
													}),
													A2(author$project$Page$Remittance$viewMaskedInput, state, account),
													A2(author$project$Buttons$viewPrimaryButton, 'Send inn', author$project$Page$Remittance$OnClickSubmit)
												]),
											validationMessage))
									]));
					}
				case 1:
					return author$project$Layout$viewSection(
						_List_fromArray(
							[
								author$project$Page$Remittance$viewBox(
								_List_fromArray(
									[
										A2(
										elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(author$project$Page$Remittance$viewRefundEntityInfo, refundEntity, partner),
												A2(
												elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														elm$html$Html$text('Vi har mottatt nødvendig informasjon for å kunne gjennomføre refusjonen. Vi vil overføre beløpet i løpet av 1-2 virkedager.')
													]))
											]))
									]))
							]));
				case 2:
					return author$project$Layout$viewSection(
						_List_fromArray(
							[
								author$project$Page$Remittance$viewBox(
								_List_fromArray(
									[
										A2(
										elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(author$project$Page$Remittance$viewRefundEntityInfo, refundEntity, partner),
												A2(
												elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														elm$html$Html$text('Vi har mottatt nødvendig informasjon for å kunne gjennomføre refusjonen. Vi vil overføre beløpet i løpet av 1-2 virkedager.')
													]))
											]))
									]))
							]));
				case 3:
					return author$project$Layout$viewSection(
						_List_fromArray(
							[
								author$project$Page$Remittance$viewBox(
								_List_fromArray(
									[
										A2(
										elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(author$project$Page$Remittance$viewRefundEntityInfo, refundEntity, partner),
												A2(
												elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														elm$html$Html$text('Vi har refundert ditt billettkjøp og overført beløpet til din bankkonto. Det kan ta 2-4 virkedager før pengene er registrert inn på din konto.')
													]))
											]))
									]))
							]));
				default:
					return A2(
						elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('Ukjent status, kontakt support')
							]));
			}
		case 0:
			return author$project$Layout$viewSection(
				_List_fromArray(
					[
						author$project$Page$Remittance$viewBox(
						_List_fromArray(
							[
								A2(
								elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										elm$html$Html$text('Laster inn...')
									]))
							]))
					]));
		default:
			var error = _n2.a;
			return author$project$Layout$viewSection(
				_List_fromArray(
					[
						author$project$Page$Remittance$viewBox(
						_List_fromArray(
							[
								A2(
								elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										elm$html$Html$text('Det skjedde en feil')
									]))
							]))
					]));
	}
};
var elm$html$Html$img = _VirtualDom_node('img');
var elm$html$Html$Attributes$src = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var author$project$Page$Remittance$viewHeader = A2(
	elm$html$Html$div,
	_List_fromArray(
		[
			elm$html$Html$Attributes$class('logo')
		]),
	_List_fromArray(
		[
			A2(
			elm$html$Html$img,
			_List_fromArray(
				[
					elm$html$Html$Attributes$src('https://ebillett.no/images/ebillett-logo.svg')
				]),
			_List_Nil)
		]));
var author$project$Page$Remittance$view = function (model) {
	var content = author$project$Page$Remittance$viewContent(model);
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('grid-container')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('header')
					]),
				_List_fromArray(
					[author$project$Page$Remittance$viewHeader])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('content')
					]),
				_List_fromArray(
					[content]))
			]));
};
var elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var elm$html$Html$map = elm$virtual_dom$VirtualDom$map;
var author$project$Main$view = function (model) {
	var viewPage = F2(
		function (body, toMsg) {
			return {
				cg: A2(
					elm$core$List$map,
					elm$html$Html$map(toMsg),
					_List_fromArray(
						[body])),
				c5: 'Refundering - DX'
			};
		});
	var _n0 = model.C;
	switch (_n0.$) {
		case 0:
			var subModel = _n0.a;
			return A2(
				viewPage,
				author$project$Page$Refund$view(subModel),
				author$project$Main$GotRefundMsg);
		case 1:
			var subModel = _n0.a;
			return A2(
				viewPage,
				author$project$Page$NoToken$view(subModel),
				author$project$Main$GotNoTokenMsg);
		default:
			var subModel = _n0.a;
			return A2(
				viewPage,
				author$project$Page$Remittance$view(subModel),
				author$project$Main$GotRemittanceMsg);
	}
};
var elm$browser$Browser$application = _Browser_application;
var author$project$Main$main = elm$browser$Browser$application(
	{cC: author$project$Main$init, cM: author$project$Main$ChangedUrl, cN: author$project$Main$ClickedLink, c4: author$project$Main$subscriptions, db: author$project$Main$update, dc: author$project$Main$view});
_Platform_export({'Main':{'init':author$project$Main$main(
	A2(
		elm$json$Json$Decode$andThen,
		function (name) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (email) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (currentTimeMillis) {
							return A2(
								elm$json$Json$Decode$andThen,
								function (apiBaseUrl) {
									return A2(
										elm$json$Json$Decode$andThen,
										function (accessToken) {
											return elm$json$Json$Decode$succeed(
												{F: accessToken, as: apiBaseUrl, av: currentTimeMillis, R: email, cK: name});
										},
										A2(
											elm$json$Json$Decode$field,
											'accessToken',
											elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														elm$json$Json$Decode$null(elm$core$Maybe$Nothing),
														A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, elm$json$Json$Decode$string)
													]))));
								},
								A2(elm$json$Json$Decode$field, 'apiBaseUrl', elm$json$Json$Decode$string));
						},
						A2(elm$json$Json$Decode$field, 'currentTimeMillis', elm$json$Json$Decode$int));
				},
				A2(
					elm$json$Json$Decode$field,
					'email',
					elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								elm$json$Json$Decode$null(elm$core$Maybe$Nothing),
								A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, elm$json$Json$Decode$string)
							]))));
		},
		A2(
			elm$json$Json$Decode$field,
			'name',
			elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						elm$json$Json$Decode$null(elm$core$Maybe$Nothing),
						A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, elm$json$Json$Decode$string)
					])))))(0)}});}(this));