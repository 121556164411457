import './main.scss';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import {login, logout, loginCallback, AUTH_ACCESS_TOKEN_KEY, AUTH_NAME, AUTH_EMAIL} from "./auth";
import * as Sentry from '@sentry/browser'

Sentry.init({ dsn: 'https://4680c41c30c14e419f9f8aaa7757392d@sentry.io/5180184' });

const now = new Date();

let apiBaseUrl = 'https://app.dx.no';

if (process.env.NODE_ENV !== 'production') {
  apiBaseUrl = 'https://dev-app.dx.no';
}

export const app = Elm.Main.init({
  flags: {
    accessToken: localStorage ? localStorage.getItem(AUTH_ACCESS_TOKEN_KEY) : null,
    name: localStorage ? localStorage.getItem(AUTH_NAME) : null,
    email: localStorage ? localStorage.getItem(AUTH_EMAIL) : null,
    apiBaseUrl: apiBaseUrl,
    currentTimeMillis: now.valueOf(),
  }
});

app.ports.login.subscribe(function() {
  login();
});
//
// app.ports.logout.subscribe(function() {
//   logout();
// });

app.ports.sentry.subscribe(function(message) {
  console.log(message);
  Sentry.captureMessage(message, "error");
});


loginCallback(app.ports.authData);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
